<template>
  <PopupMenuItem :value="value" :selected="selected" :new-section="newSection" @click="onClick">
    <i :class="['fa', 'fa-' + faIcon]"></i>
  </PopupMenuItem>
</template>

<script setup>
import PopupMenuItem from "@/components/menus/PopupMenuItem.vue";

const props = defineProps({
  selected: Boolean,
  value: String,
  faIcon: String,
  newSection: Boolean
});

const emit = defineEmits(['click']);

function onClick() {
  emit('click', props.value);
}

</script>

<style lang="scss" scoped>
li {
  flex-basis: 30% !important;
  text-align: center !important;
  font-size: 3vh;
}
</style>

