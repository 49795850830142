<template>
    <div class="check-question">
        <h4>Testing your internet connection...</h4>
        <div class="check-content">
            <div class="waiting">
                <WaitingAnimation></WaitingAnimation>
            </div>
        </div>
    </div>
</template>

<script setup>
import WaitingAnimation from '@/components/layout/WaitingAnimation';
import { onMounted, onUnmounted } from 'vue';

const emit = defineEmits(['complete', 'runNetworkCheck']);
const props = defineProps(['participant']);

onMounted(() => {
    runTest();
});

async function runTest() {
    emit('runNetworkCheck', onTestComplete);
}

function onTestComplete(testResults) {
    var status = 'Failed',
        message;

    switch (testResults.result) {
        case 'aborted':
        case 'failed':
            message = `We were unable to test your internet connection. Please check your connection and try again.`
            break;
        case 'bad':
            message = `Your internet connection might not be good enough for an online lesson. Please try a different network if possible, or close down any other applications that might be using it.`
            break;
        case 'warning':
            message = `We detected a few problems with your internet connection. Your video or audio might be a bit choppy in the lesson. Try restarting your device, or closing down any other applications that might be using it.`
            status = 'Warning';
            break;
        default:
            status = 'Passed';
    }

    emit('complete', { status: status, message: message });
}

</script>
  
<style lang="scss" scoped>
h4 {
    margin-bottom: 30px
}

.waiting {
    position: relative;
    height: 150px;
}
</style>
  