// import Vue from 'vue';
import router from './scripts/router.js';
import env from '@/env.js';
import { createApp } from 'vue';
import App from './App.vue';
import * as Sentry from "@sentry/vue";

const app = createApp(App);

if (!env.dev) {
    Sentry.init({
        app,
        dsn: "https://974038e7c68a4331bb641175565f2832@o1047336.ingest.sentry.io/6485297",
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

app.use(router);
app.mount("#app");
