<template>
  <IconButton ref="button" :icon-class="iconClass" :icon="icon" :fa-icon="faIcon" :size="size" :label="label" :alt="alt"
    :class="[shadow ? 'with-shadow' : '']" @click="onClick" />
</template>

<script setup>
import { computed, ref } from 'vue';
import IconButton from "@/components/buttons/IconButton.vue";

const button = ref(null);

const props = defineProps({
  icon: String,
  faIcon: String,
  label: String,
  size: String,
  iconClass: String,
  shadow: { type: Boolean, default: true },
  alt: String
});

const emit = defineEmits(['click']);

const el = computed(() => {
  return button.value?.el;
})

defineExpose({ el });

function onClick(e) {
  emit('click', e);
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.icon-button {
  &.with-label {
    padding-bottom: calc(3vh + 5px) !important;
  }
}

:deep {

  .icon-button-icon {
    border-radius: 50%;
    height: 5vh !important;
  }

  &.small {
    .icon-button-icon {
      height: 3.5vh !important;
    }
  }

  &.large {
    .icon-button-icon {
      height: 7vh !important;
    }
  }

  &.with-shadow {
    .icon-button-icon {
      box-shadow: $shadow
    }
  }
}
</style>
