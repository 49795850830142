<template>
    <ClassroomLayout>
        <div ref="callFrame" class="call-frame h-100 d-flex flex-column">
            <div ref="callFrameBody" class="call-frame-body d-flex">
                <template v-if="hasParticipants">
                    <template v-if="activeScreenShareParticipant">
                        <ScreenTile ref="screenTileEl" :participant="activeScreenShareParticipant" :admin="isTeacher"
                            :screen-share-settings="screenShareSettings" @pinClick="onScreenSharePinClick"
                            @hideClick="onScreenShareHideClick" />
                    </template>
                    <div ref="callFrameParticipants"
                        :style="!activeScreenShareParticipant ? { maxWidth: callFrameParticipantsWidth } : null"
                        :class="['col call-frame-participants', activeScreenShareParticipant ? 'with-screenShare' : 'align-self-center']">
                        <div v-for="p in allParticipants" :key="p.session_id" class="participant"
                            :style="{ flexBasis: participantWidth }">
                            <WaitingVideoTile v-if="p.isWaiting"
                                :size="activeScreenShareParticipant ? 'small' : 'normal'" :participant="p"
                                @admitClick="onAdmitClick" @denyClick="onDenyClick" />
                            <ParticipantVideo v-else :admin="isTeacher" :show-name="true"
                                :size="activeScreenShareParticipant ? 'small' : 'normal'" :participant="p"
                                :is-pinned-screen-share="screenShareSettings.pinned === p.session_id"
                                :is-active-screen-share="activeScreenShareSessionId === p.session_id"
                                :participant-data="p.userData?.emoji" @mute="onParticipantMuteClick"
                                @hide="onParticipantHideClick" @screenShareClick="onScreenShareToggle"
                                @stopScreenShare="onParticipantStopScreenShare" @dismissHand="onDismissHand"
                                @eject="onEjectClick" @toggleBackground="onToggleBackground" />
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <template #controls>
            <CallControls :enable-screen-share="enableScreenShare" :enable-react="allowReact"
                :participant="localParticipant" :admin="isTeacher" :lesson-data="lessonData" v-bind="$attrs">
            </CallControls>
        </template>

        <template #sidePanel>
            <SidePanel ref="sidePanel" :notification-count="chatNotificationCount > 0 ? chatNotificationCount : null"
                @open="onSidePanelOpen">
                <template v-if="enablePeople">
                    <SidePanelSection v-for="s in students" :key="s.key" class="side-panel-section-student"
                        :title="s.user_name" fa-icon="person-simple">
                        <StudentTile :participant="s"
                            :logins="lessonData.studentLogins ? lessonData.studentLogins[s.userData?.studentUuid] : null" />
                    </SidePanelSection>
                    <SidePanelDivider />
                </template>
                <template v-if="showChallenges">
                    <SidePanelSection title="Challenges" fa-icon="trophy">
                        <ChallengesTile :participants="students" :expected-participants="expectedParticipants"
                            @challenge-changed="onChallengeChanged" />
                    </SidePanelSection>
                </template>
                <template v-if="surveyUrl">
                    <SidePanelSection title="Survey" fa-icon="clipboard-list-check">
                        <SurveyTile :url="surveyUrl" />
                    </SidePanelSection>
                </template>
                <SidePanelSection title="Settings" fa-icon="gear">
                    <SettingsTile :devices="devices" v-bind="$attrs" :classroom-settings="classroomSettings"
                        :admin="isTeacher" @toggleChat="onToggleChat" />
                </SidePanelSection>
                <SidePanelSection v-if="infoItems" title="Info" fa-icon="circle-info">
                    <InfoTile :items="infoItems" />
                </SidePanelSection>
                <SidePanelSection title="Chat" fa-icon="comments" :collapsible="false" :open-by-default="true">
                    <ChatTile :messages="messages" :enabled="classroomSettings.enableChat || isTeacher"
                        :admin="isTeacher" @sendMessage="sendMessage" @deleteMessage="onDeleteChatMessage" />
                </SidePanelSection>
            </SidePanel>
        </template>
    </ClassroomLayout>
</template>

<script setup>
import ParticipantVideo from "./video/ParticipantVideo.vue";
import WaitingVideoTile from "./video/WaitingVideoTile.vue";
import domHelpers from '@/scripts/helpers/domHelpers.js';
import StudentTile from "./sidePanel/people/StudentTile.vue";
import ChallengesTile from "./sidePanel/challenges/ChallengesTile.vue";
import SurveyTile from "./sidePanel/survey/SurveyTile.vue";
import SettingsTile from "./sidePanel/settings/SettingsTile.vue";
import ChatTile from "./sidePanel/chat/ChatTile.vue";
import InfoTile from "./sidePanel/info/InfoTile.vue";
import ScreenTile from "@/components/classroom/ScreenTile.vue";
import ClassroomLayout from "@/components/classroom/ClassroomLayout.vue";
import SidePanel from "@/components/classroom/sidePanel/SidePanel.vue";
import SidePanelSection from "@/components/classroom/sidePanel/SidePanelSection.vue";
import SidePanelDivider from "@/components/classroom/sidePanel/SidePanelDivider.vue";
import CallControls from "@/components/classroom/CallControls.vue";
import { ref, onMounted, onUnmounted, computed, inject, watch } from 'vue';
import _ from 'lodash';

var resizeObservers = [];

const isTeacher = inject('isTeacher'),
    callFrameBodyDimensions = ref(null),
    callFrameParticipantsDimensions = ref(null),
    callFrameBody = ref(null),
    callFrameParticipants = ref(null),
    participantWidth = ref(null),
    callFrameParticipantsWidth = ref(null),
    screenTileEl = ref(null),
    activeScreenShareSessionId = ref(null),
    sidePanel = ref(null),
    chatNotificationCount = ref(0);

const props = defineProps({
    participants: Object,
    waitingParticipants: Object,
    expectedParticipants: Object,
    localParticipant: Object,
    enablePeople: Boolean,
    enableChat: Boolean,
    enableScreenShare: Boolean,
    lessonData: Object,
    platform: Object,
    messages: Object,
    screenShares: Object,
    classroomSettings: Object,
    screenShareSettings: Object,
    devices: Object,
    theme: String
});

const emit = defineEmits(['sendMessage',
    'deleteChatMessage',
    'participantHideClick',
    'participantMuteClick',
    'admitClick',
    'denyClick',
    'pinScreenShare',
    'stopParticipantScreenShare',
    'toggleChat',
    'dismissHand',
    'eject',
    'toggleBackground',
    'challengeChanged']);

const messagesLength = computed(() => {
    return props.messages?.length || 0;
});

watch(() => props.participants, () => { setParticipantWidth(); }, { immediate: true });
watch(() => props.screenShares, (newScreenShare, oldScreenShare) => { updateScreenShares(newScreenShare, oldScreenShare); }, { immediate: true });
watch(messagesLength, (newLength, oldLength) => { onMessagesChanged(newLength, oldLength); });

// Returns participants combined with waiting participants
// Teacher first, then waiting, then others
const allParticipants = computed(() => {
    return sortByTeacherThenName(_.concat(props.participants, props.waitingParticipants || []));
});

function sortByTeacherThenName(participants) {
    return _.sortBy(participants, (p) => {
        return (p.permissions?.canAdmin ? '__' : p.isWaiting ? '_' : '') + (p.name || p.user_name, 'desc');
    });
}

const hasParticipants = computed(() => {
    return props.participants ? true : false;
});

const allowReact = computed(() => {
    return true;
});

const infoItems = computed(() => {
    var items;

    if (props.platform) {
        items = [];
        items.push({ label: `${props.platform.name} website`, value: props.platform.url?.replace('https://', ''), url: props.platform.url });
        if (props.platform.username) {
            items.push({ label: `${props.platform.name} username`, value: props.platform.username });
            items.push({ label: `${props.platform.name} password`, value: props.platform.password });
        }
    }

    return items;
});

const showChallenges = computed(() => {
    return props.lessonData.weekNumber === 4 && props.enablePeople && getStudents();
});

const surveyUrl = computed(() => {
    var result = null,
        now = new Date();

    // Only return a URL if we're in the right week for the survey to show
    if (now > new Date('2024-09-23') && now < new Date('2024-09-30') && props.theme === 'bootcamp' && !isTeacher.value) {
        result = `https://forms.gle/kgrQGPr9Q2TEuGLXA`;
    }

    return result;
});

const activeScreenShareParticipant = computed(() => {
    return getActiveScreenShareParticipant();
});

// Return the screen share to show for this user
function getActiveScreenShareParticipant() {
    if (props.screenShares) {
        // Teacher sees the screen share they've marked as active
        return (isTeacher.value && getScreenShareBySessionId(activeScreenShareSessionId.value)) ||
            // Everyone else sees the one that's pinned
            (!isTeacher.value && getScreenShareBySessionId(props.screenShareSettings.pinned));
    }
}

function getScreenShareBySessionId(sessionId) {
    return _.find(props.screenShares, (s) => { return s.session_id === sessionId });
}

function onScreenSharePinClick(data) {
    emit('pinScreenShare', data);
}

function onScreenShareHideClick() {
    activeScreenShareSessionId.value = null;
}

function updateScreenShares(newScreenShare, oldScreenShare) {
    if (!activeScreenShareSessionId.value && isTeacher.value && hasLocalScreenShare(newScreenShare) && !hasLocalScreenShare(oldScreenShare)) {
        activeScreenShareSessionId.value = props.localParticipant.session_id;
    }
}

function hasLocalScreenShare(screenShares) {
    return _.find(screenShares, (p) => { return p.local; });
}

function onChallengeChanged(data) {
    emit('challengeChanged', data);
}

function onToggleChat() {
    emit('toggleChat');
}

function setParticipantWidth() {
    var flexSizes,
        containerDimensions,
        count;

    containerDimensions = getActiveScreenShareParticipant() ? callFrameParticipantsDimensions.value : callFrameBodyDimensions.value;

    if (containerDimensions && callFrameParticipants.value) {
        count = props.participants.length + (props.waitingParticipants?.length || 0);

        flexSizes = domHelpers.getVideoFlexBasis(containerDimensions.width, containerDimensions.height, count);
        participantWidth.value = flexSizes.videoWidth + 'px';
        callFrameParticipantsWidth.value = flexSizes.containerWidth + 'px';
    }
}

const students = computed(() => {
    // Only show students (not admin users)
    return getStudents();
});

function getStudents() {
    return props.participants.filter((p) => !p.permissions?.canAdmin);
}

onMounted(() => {
    domHelpers.watchResize(resizeObservers, 'callFrameBody', callFrameBody.value, callFrameBodyDimensions, onCallFrameBodyResize);
    domHelpers.watchResize(resizeObservers, 'callFrameParticipants', callFrameParticipants.value, callFrameParticipantsDimensions, onCallFrameParticipantsResize);
});


onUnmounted(() => {
    for (var resizeObserver of resizeObservers) {
        resizeObserver.disconnect();
    }

    resizeObservers = null;
})

function onCallFrameBodyResize() {
    if (callFrameBody.value) {
        clearWidths();

        if (screenTileEl.value) {
            screenTileEl.value.setSize();
        }

        setParticipantWidth();
    }
}

function onCallFrameParticipantsResize() {
    setParticipantWidth();
}

function clearWidths() {
    participantWidth.value = null;
    callFrameParticipantsWidth.value = null;

    // Force repaint
    getComputedStyle(callFrameBody.value).height;
}

function onScreenShareToggle(participant) {
    activeScreenShareSessionId.value = activeScreenShareSessionId.value === participant.session_id ? null : participant.session_id;
}

function onEjectClick(participant) {
    emit('eject', participant);
}

function onToggleBackground(participant) {
    emit('toggleBackground', participant);
}

function onParticipantMuteClick(participant) {
    emit('participantMuteClick', participant);
}

function onParticipantHideClick(participant) {
    emit('participantHideClick', participant);
}

function onDismissHand(participant) {
    emit('dismissHand', participant);
}

function onAdmitClick(participant) {
    emit('admitClick', participant);
}

function onDenyClick(participant) {
    emit('denyClick', participant);
}

function onParticipantStopScreenShare(participant) {
    emit('stopParticipantScreenShare', participant);
}

function sendMessage(message) {
    emit('sendMessage', message);
}

function onDeleteChatMessage(id) {
    emit('deleteChatMessage', id);
}

function onSidePanelOpen() {
    chatNotificationCount.value = 0;
}

function onMessagesChanged(newLength, oldLength) {
    if (newLength > oldLength && (!sidePanel.value || !sidePanel.value.isOpen)) {
        chatNotificationCount.value += newLength - oldLength;
    }
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.call-frame {

    &-body {
        display: flex;
        justify-content: center;
        overflow: hidden;
        flex-grow: 1;
    }

    &-participants {
        flex-grow: 1;
        flex-basis: 150px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        align-content: flex-start;
        overflow: auto;

        .participant {
            flex-grow: 0;
            flex-basis: 30%;
            min-width: 200px;
        }

        &.with-screenShare {
            .participant {
                max-width: 100%;
                flex-basis: 100%;
                min-width: 150px;
            }
        }
    }
}

:deep {
    .side-panel-section-student {
        .side-panel-section-title {
            background: $lightestIndigo;
        }
    }
}
</style>
