<template>
  <div ref="journeyDot"
    :class="['journey-dot', `journey-dot-${position}`, `journey-dot-${lesson.status}`, `journey-dot-${side}`]">
    <i v-if="icon" :class="[`fa`, `fa-${icon}`]"></i>
    <div v-if="lesson.isLatest" ref="cocoIsHereEl" class="coco-is-here">
      <img :src="cocoIsHereImage" />
    </div>
  </div>
  <ToolTip :message="tooltipMessage" :title="title" :anchor="journeyDot" />
</template>

<script setup>
import ToolTip from "@/components/ToolTip.vue";
import dateTimeHelpers from "@/scripts/helpers/dateTimeHelpers";
import cocoIsHereLeft from "@/assets/journey/cocoIsHere-left.png";
import cocoIsHereRight from "@/assets/journey/cocoIsHere-right.png";
import { ref, computed, onMounted } from "vue";

const journeyDot = ref(null),
  cocoIsHereEl = ref(null);

const props = defineProps({
  lesson: Object,
  position: String,
  side: String,
  theme: Object
});

onMounted(() => {
  if (cocoIsHereEl.value) {
    cocoIsHereEl.value.scrollIntoView({ behavior: "smooth", block: "center" });
    setTimeout(() => {
      cocoIsHereEl.value.classList.add('on');
    }, 1000);
  }
});

const icon = computed(() => {
  var result;

  switch (props.lesson.status) {
    case 'paused':
      result = 'pause';
      break;
    case 'locked':
      result = 'lock-keyhole';
      break;
  }

  return result;
});

const cocoIsHereImage = computed(() => {
  var result;

  if (props.side === 'left') {
    result = ['first', 'second'].includes(props.position) ? cocoIsHereLeft : cocoIsHereRight;
  }
  else {
    result = ['first', 'second'].includes(props.position) ? cocoIsHereRight : cocoIsHereLeft;
  }

  return result;
});

const title = computed(() => {
  return dateTimeHelpers.getFullDateString(new Date(props.lesson.date));
});

const tooltipMessage = computed(() => {
  var result;

  switch (props.lesson.status) {
    case 'present':
      result = `You attended this ${props.theme.name} lesson`;
      break;
    case 'absent':
      result = 'You missed this lesson';
      break;
    case 'away':
      result = lessonIsInFuture() ? 'You will be away for this lesson' : 'You were away during this lesson';
      break;
    case 'paused':
      result = 'Your subscription was paused for this lesson';
      break;
    case 'locked':
      result = `This lesson hasn't happened yet`;
      break;
  }

  return result;
});

function lessonIsInFuture() {
  return new Date(props.lesson.date) > new Date();
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.journey-dot {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: 1px solid white;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  position: relative;
  z-index: 100;

  &:hover {
    transform: scale(1.5);
  }

  &-present {
    background: white;
  }

  &-locked {
    font-size: 1.3rem;
    border: none;
    opacity: 0.5;
  }

  .coco-is-here {
    position: absolute;
    width: 6rem;
    z-index: 100;
    transform: scale(0);
    transition: all 0.2s ease-in-out;
    transform-origin: left;

    &.on {
      transform: scale(1);
    }

    img {
      width: 100%;
      filter: drop-shadow($shadow);
    }
  }

  &-left {

    &.journey-dot-first,
    &.journey-dot-second {
      .coco-is-here {
        left: -5.5rem;
      }
    }

    &.journey-dot-third,
    &.journey-dot-fourth {
      .coco-is-here {
        left: 0.5rem;
      }
    }
  }

  &-right {

    &.journey-dot-first,
    &.journey-dot-second {
      .coco-is-here {
        left: 0.5rem;
      }
    }

    &.journey-dot-third,
    &.journey-dot-fourth {
      .coco-is-here {
        left: -5.5rem;
      }
    }
  }
}
</style>
