<template>
    <div class="student-tile d-flex flex-column">
        <InfoList v-if="loginInfoItems" :items="loginInfoItems" />
        <div v-else>No logins found</div>
    </div>
</template>

<script setup>
import InfoList from "@/components/classroom/sidePanel/info/InfoList.vue";
import { computed } from "vue";

const props = defineProps({
    participant: Object,
    logins: Object
});

const loginInfoItems = computed(() => {
    var result;

    if (props.logins) {
        result = [];

        for (var login of props.logins) {
            if (login.username) {
                result.push({ label: `${login.platformName} username`, value: login.username });
                result.push({ label: `${login.platformName} password`, value: login.password });
            }
        }
    }

    return result;
});
</script>

<style lang="scss">
@import '@/styles/variables.scss';

.student-tile {
    padding: 2vh;

    .empty-state {
        opacity: 0.7;
    }
}
</style>