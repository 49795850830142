<template>
    <div>
        <ul class="challenge-header">
            <li class="challenge-header-col">
                <ChallengeRowBadge challenge="Attempt" />
            </li>
            <li class="challenge-header-col">
                <ChallengeRowBadge challenge="Kilo" />
            </li>
            <li class="challenge-header-col">
                <ChallengeRowBadge challenge="Mega" />
            </li>
            <li class="challenge-header-col">
                <ChallengeRowBadge challenge="Giga" />
            </li>
        </ul>
        <ChallengeRow v-for="p in allParticipants" :key="p.key" :participant="p"
            @challenge-changed="onChallengeChanged" />
    </div>
</template>

<script setup>
import { computed } from 'vue';
import _ from 'lodash';
import ChallengeRow from "@/components/classroom/sidePanel/challenges/ChallengeRow.vue";
import ChallengeRowBadge from "@/components/classroom/sidePanel/challenges/ChallengeRowBadge.vue";

const emit = defineEmits(['challengeChanged']);

const props = defineProps({
    participants: Object,
    expectedParticipants: Object
});

const allParticipants = computed(() => {
    var missing,
        found,
        result;

    if (!props.expectedParticipants) {
        result = props.participants;
    }
    else if (!props.participants || props.participants.length === 0) {
        result = props.expectedParticipants;
    }
    else {
        // Merge participants and expectedParticipants userData
        result = [];
        for (var ep of props.expectedParticipants) {
            found = false;
            for (var p of props.participants) {
                if (p.userData?.studentUuid === ep.userData?.studentUuid) {
                    p.userData = _.merge(p.userData, ep.userData);
                    found = true;
                    break;
                }
            }

            if (!found) {
                missing = missing || [];
                missing.push(ep);
            }
        }

        // Sort alphabetically by name
        result = _.sortBy(missing ? _.concat(props.participants, missing) : props.participants, (a) => { return a?.user_name });
    }


    return result;
});

function onChallengeChanged(data) {
    emit('challengeChanged', data);
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';

.challenge-header {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0.5vh 0;
    justify-content: right;

    li {
        input {
            margin: 0 5px;
        }
    }

    &-col {
        width: 30px;
        text-align: center;
        cursor: default;
    }
}
</style>