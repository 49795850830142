// import { Events } from '@/logic/events';
import { Emitter } from '@/logic/emitter';

export class CallStatus {
    emitter = new Emitter(this);
    monitorDelay = 10000;
    timers = { network: null, cpu: null };
    statuses = {
        network: ['very-low', 'low', 'good'],
        cpu: ['high', 'low']
    };

    // Default the statuses to the highest (best) levels
    currentStatus = { network: 'good', cpu: 'low' };
    monitorStatus = { network: 'good', cpu: 'low' };

    get status() {
        return this.getStatus();
    }

    getStatus() {
        var result = { ...this.currentStatus };
        result.mode = this.getMode();

        return result;
    }

    get mode() {
        return this.getMode();
    }

    getMode() {
        var result;
        if (this.currentStatus.cpu === 'high' || this.currentStatus.network === 'very-low') {
            result = 'bad';
        }
        else if (this.currentStatus.network === 'low') {
            result = 'ok';
        }
        else {
            result = 'good';
        }

        return result;
    }

    getStatusIndex(type, name) {
        var names = this.statuses[type];
        for (var i in names) {
            if (names[i] === name) {
                return i;
            }
        }
    }

    handleStatusEvent(type, status) {
        var diff;

        // If the status has changed since last update
        if (this.monitorStatus[type] !== status) {
            // If the last monitor status was higher/lower than the current status,
            // and the new status is in the same direction, shift the current status up/down one level
            // This avoids the situation where the monitor status fluctuates between different statuses
            // but never settles long enough to update the current status
            diff = this.getStatusDiff(type, this.monitorStatus[type]);
            if (diff && diff === this.getStatusDiff(type, status)) {
                this.updateCurrentStatus(type, diff);
            }

            // Restart the timer
            this.resetMonitorTimer(type);
            // And start monitoring the new status
            this.monitorStatus[type] = status;
        }
    }

    // Return -1 or 1 to indicate which direction the specified status is compared to the current status
    // -1 means a status lower (worse) than current, 1 means a status higher (better) than current
    getStatusDiff(type, name) {
        var newStatusIndex,
            currentStatusIndex,
            result;

        newStatusIndex = this.getStatusIndex(type, name);
        currentStatusIndex = this.getStatusIndex(type, this.currentStatus[type]);
        result = -1 * (currentStatusIndex - newStatusIndex);
        result = Math.min(Math.max(result, -1), 1);

        return result;
    }

    resetMonitorTimer(type) {
        var self = this;
        this.timers[type] = this.resetTimer(this.timers[type], this.monitorDelay, () => { self.updateCurrentStatus(type) });
    }

    // Clears and restarts a timer
    resetTimer(timer, delay, fn) {
        if (timer) {
            clearTimeout(timer);
        }

        return setTimeout(() => {
            fn();
        }, delay);
    }

    // Fired when a monitor timeout ends
    // which means the user's status has been the same for the duration of the time
    updateCurrentStatus(type, adjustment = null) {
        var newStatus,
            currentStatusIndex;

        if (adjustment) {
            currentStatusIndex = parseInt(this.getStatusIndex(type, this.currentStatus[type]));
            newStatus = this.statuses[type][currentStatusIndex + adjustment];
        }
        else {
            newStatus = this.monitorStatus[type];
        }

        this.currentStatus[type] = newStatus;

        this.emitStatusEvent(type);
    }

    // Fire an event when the status has changed
    emitStatusEvent(type) {
        var data;

        data = {
            mode: this.getMode(),
            type: type,
            status: this.currentStatus[type]
        }

        this.emitter.emit('status-changed', data);
    }
}