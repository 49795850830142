<template>
    <p class="chat-message">
        <span class="chat-name">{{ message.name }}: </span><span class="chat-message-text" v-html="message.content"></span>
        <CircleButton v-if="admin" class="delete-button" fa-icon="trash-can" size="small" :shadow="false" icon-class="delete-icon"
            @click="onDelete" />
    </p>
</template>
  
<script setup>
import CircleButton from "@/components/buttons/CircleButton.vue";

const props = defineProps({
    admin: Boolean,
    message: Object
});

var emit = defineEmits(['delete']);

function onDelete() {
    emit('delete', props.message.id);
}

</script>

<style lang="scss">
@import '@/styles/variables.scss';

.chat-message-text {
    a {
        color: $red !important;
    }
}
</style>
  
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Ropa+Sans&display=swap");
@import '@/styles/variables.scss';

.chat-message {
    color: #121a24;
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 20px;
    position: relative;

    .chat-name {
        color: #6b7785;
    }

    &:hover {
        .delete-button {
            opacity: 1
        }
    }

    .delete-button {
        position: absolute;
        right: 0;
        top: 18px;
        transform: translateY(-50%);
        color: $darker !important;
        opacity: 0;
        transition: all 0.3s;
    }

    :deep {
        .delete-icon {
            border: 1px solid $darker;
            background: white;
        }
    }
}
</style>
  