export class Emitter {
    constructor(host) {
        this.eventMap = new Map();

        if (host) {
            host.on = (label, fn) => {
                this.on(label, fn);
            }

            host.off = (label, fn) => {
                this.off(label, fn);
            }
        }
    }

    on(event, callback) {
        if (!this.eventMap.has(event)) {
            this.eventMap.set(event, []);
        }
        this.eventMap.get(event).push(callback);
    }

    off(event, callback) {
        if (this.eventMap.has(event)) {
            const callbacks = this.eventMap.get(event).filter(cb => cb !== callback);
            this.eventMap.set(event, callbacks);
        }
    }

    emit(event, ...data) {
        if (this.eventMap.has(event)) {
            this.eventMap.get(event).forEach(callback => {
                setTimeout(() => callback(...data), 0);
            });
        }
    }
}