<template>
    <ClassroomLayout :confetti="state == 'badges'">
        <template #back>
            <BackButton class="secondary" label="Rejoin lesson" @click="onJoinClick" />
        </template>
        <div class="classroom-ended">
            <template v-if="state == 'waiting'">
                <WaitingAnimation></WaitingAnimation>
            </template>
            <template v-else-if="state == 'teacher'">
                <h3>{{ message || 'You have left the lesson' }}</h3>
                <div class="button" @click="onJoinClick">Join again</div>
            </template>
            <template v-else-if="state == 'denied'">
                <h3>You are not able to join this lesson</h3>
            </template>
            <template v-else-if="state == 'survey'">
                <h3>Please answer some questions about your lesson...</h3>
                <Survey :fillout-id="data.survey.filloutId" :student-uuid="studentUuid" :lesson-uuid="lessonUuid"
                    :survey-name="data.survey.name" @submit="onSurveySubmit" />
            </template>

            <template v-else-if="state == 'badges'">
                <div v-if="data.level" class="classroom-ended-badges">
                    <h3>You've reached a new level!</h3>
                    <JourneyLevelBanner :level="data.level" />
                </div>
                <div v-if="data.badges" class="classroom-ended-badges">
                    <h3>{{ badgeMessage }}</h3>
                    <BadgeSet :badges="data.badges"></BadgeSet>
                    <NextButton v-if="showJourney" class="nextButton" @click="onNextClick" />
                </div>
            </template>
            <template v-else>
                <h3>You have left the lesson</h3>
                <NextButton v-if="showJourney" class="nextButton" @click="onNextClick" />
            </template>
        </div>
    </ClassroomLayout>
</template>

<script setup>

import ClassroomLayout from "@/components/classroom/ClassroomLayout.vue";
import { computed, onMounted, ref } from "vue";
import apiHelpers from '@/scripts/helpers/apiHelpers.js';
import WaitingAnimation from '@/components/layout/WaitingAnimation';
import BadgeSet from '@/components/badges/BadgeSet.vue';
import NextButton from '@/components/buttons/NextButton.vue';
import BackButton from '@/components/buttons/BackButton.vue';
import JourneyLevelBanner from "@/components/journey/JourneyLevelBanner.vue";
import Survey from '@/components/classroom/Survey.vue';
import { useRouter } from 'vue-router';
const router = useRouter();

const emit = defineEmits(['joinClick', 'nextClick']);

const props = defineProps({
    denied: Boolean,
    teacherUuid: String,
    studentUuid: String,
    lessonUuid: String,
    showJourney: Boolean
});

const data = ref(null),
    state = ref('waiting');

onMounted(() => {
    setupPage();
});

const badgeMessage = computed(() => {
    return `You've earned ${data.value.badges.length > 1 ? 'new badges' : 'a new badge'}!`;
});

async function setupPage() {

    if (props.teacherUuid) {
        state.value = 'teacher';
    }
    else if (props.denied) {
        state.value = 'denied';
    }
    else {
        data.value = await fetchData();

        if (data.value) {

            if (data.value.survey) {
                state.value = 'survey';
            }
            else if (data.value.badges) {
                state.value = 'badges';
            }
            else {
                state.value = 'ended';
            }
        }
    }




}

async function fetchData() {
    var endpoint;

    if (props.studentUuid) {
        endpoint = `postLesson/${props.studentUuid}/${props.lessonUuid}`;

        return await apiHelpers.get(endpoint, true);
    }
}

function onJoinClick() {
    emit('joinClick');
}

function onNextClick() {
    emit('nextClick');
    router.push({ name: 'Journey', params: { studentUuid: props.studentUuid } })
}

function onSurveySubmit() {
    state.value = data.value.badges ? 'badges' : 'ended';

    recordSurveyResponse();
}

async function recordSurveyResponse() {
    var params;

    params = {
        surveyUuid: data.value.survey.uuid,
        studentUuid: props.studentUuid,
        lessonUuid: props.lessonUuid
    };

    await apiHelpers.post(`survey/student`, params);
}

</script>

<style lang="scss" scoped>
.classroom-ended {
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
        margin-top: 50px;
        margin-bottom: 50px
    }

    &-badges {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 3rem;
    }
}

.nextButton {
    margin-top: 50px
}

:deep {
    .level-banner {
        width: 8rem;
    }
}
</style>
