<template>
    <span class="label">{{ item.label }}:</span>
    <span class="value" @click="onItemClick">
        <span>{{ item.value }}</span>
        <img v-if="!item.url" :class="copied ? 'on' : 'off'" :src="tickWhiteIcon" />
        <img v-if="!item.url" :class="!copied ? 'on' : 'off'" :src="clipboardWhiteIcon" />
        <img v-if="item.url" :src="linkWhiteIcon" />
    </span>
</template>
  
<script setup>
import { ref } from 'vue';
import tickWhiteIcon from "@/assets/tick-white.png";
import clipboardWhiteIcon from "@/assets/clipboard-white.png";
import linkWhiteIcon from "@/assets/link-white.png";

const copied = ref(false);

const props = defineProps({
    item: Object
});

function onItemClick() {
    if (props.item.url) {
        window.open(props.item.url, '_blank');
    }
    else {
        copyValue();

    }
}

function copyValue() {
    // Copy value to clipboard
    navigator.clipboard.writeText(props.item.value);
    copied.value = true;
    setTimeout(() => {
        copied.value = false;
    }, 3000);
}


</script>

<style lang="scss">
@import '@/styles/variables.scss';

    span {
        display: inline-block
    }

    .label {
        padding-right: 5px;
    }

    .value {
        padding: 2px 10px;
        background-color: $indigo;
        color: white;
        border-radius: 100px;
        cursor: pointer;
        padding-right: 25px;
        position: relative;

        img {
            position: absolute;
            top: 5px;
            right: 4px;
            width: 14px;
            display: inline-block;
            transition: opacity 0.3s;
            opacity: 1;

            &.on {
                opacity: 1;
            }

            &.off {
                opacity: 0;
            }
        }
    }

</style>
  