<template>
    <div>
        <div class="start-banner">
            <div class="start-banner-inner"><img :src="startBanner" /></div>
        </div>
    </div>
    <div class="journey-sections">
        <div class="journey-sections-inner">
            <template v-for="(section, i) in sectionsData" :key="i">
                <JourneySection :odd="i % 2 == 0" :section="section" />
            </template>
        </div>
    </div>
</template>

<script setup>
import JourneySection from "@/components/journey/JourneySection.vue";
import { computed } from "vue";
import startBanner from "@/assets/journey/startBanner.png";

const props = defineProps({
    sections: Object
});

const sectionsData = computed(() => {
    var result = [],
        lessons = 0;

    for (var section of props.sections) {
        section.side = (lessons / 2) % 2 == 0 ? 'right' : 'left';
        section.size = section.lessons.length == 1 ? 'half' : section.lessons.length == 2 ? 'single' : 'double';
        result.push(section);
        lessons += Math.ceil(section.lessons.length / 2) * 2;
    }

    return result;
});

</script>

<style lang="scss" scoped>
.start-banner {
    z-index: 1;
    position: relative;
    margin-top: 1rem;
    top: 1rem;
    display: flex;
    justify-content: center;

    &-inner {
        width: 12rem;
    }

    img {
        width: 100%;
    }
}

.journey-sections {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-inner {
        width: 100%;
    }
}
</style>
