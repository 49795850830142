<template>
    <ul class="challenge-row">
        <li class="challenge-row-name">
            <div>{{ participant.user_name }}</div>
        </li>
        <li class="challenge-row-tick">
            <input ref="attemptEl" type="checkbox" name="attempt" value="Attempt" :checked="hasAttemptBadge"
                @change="attemptClicked">
        </li>
        <li class="challenge-row-tick">
            <input ref="kiloEl" type="checkbox" name="kilo" value="Kilo" :checked="hasKiloBadge" @change="challengeClicked">
        </li>
        <li class="challenge-row-tick">
            <input ref="megaEl" type="checkbox" name="mega" value="Mega" :checked="hasMegaBadge" @change="challengeClicked">
        </li>
        <li class="challenge-row-tick">
            <input ref="gigaEl" type="checkbox" name="giga" value="Giga" :checked="hasGigaBadge" @change="challengeClicked">
        </li>
    </ul>
</template>

<script setup>
import { ref, computed } from "vue";

const attemptEl = ref(null),
    kiloEl = ref(null),
    megaEl = ref(null),
    gigaEl = ref(null);

const emit = defineEmits(['challengeChanged']);

const props = defineProps({
    participant: Object
});

const hasAttemptBadge = computed(() => {
    return hasChallengeBadge('attempt');
})

const hasMegaBadge = computed(() => {
    return hasChallengeBadge('mega');
})

const hasKiloBadge = computed(() => {
    return hasChallengeBadge('kilo');
})

const hasGigaBadge = computed(() => {
    return hasChallengeBadge('giga');
})

function hasChallengeBadge(type) {
    return props.participant.userData?.challengeBadges?.includes(`challenge-${type}`);
}

function attemptClicked(e) {
    var checked = e.target.checked;
    changeChallenge(e.target.value, checked);

    if (checked) {
        changeChallenge('Kilo', false, kiloEl.value);
        changeChallenge('Mega', false, megaEl.value);
        changeChallenge('Giga', false, gigaEl.value);
    }
}

function challengeClicked(e) {
    var checked = e.target.checked;
    changeChallenge(e.target.value, checked);

    if (checked) {
        changeChallenge('Attempt', false, attemptEl.value);
    }
}

function changeChallenge(type, achieved, el) {
    var changed = true;

    if (el) {
        if (el.checked === achieved) {
            changed = false;
        }
        else {
            el.checked = achieved;
        }
    }

    if (changed) {
        emit('challengeChanged', { participant: props.participant, type: type, achieved: achieved });
    }
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';

.challenge-row {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0.25vh 0;

    li {
        input {
            margin: 0 5px;
        }
    }

    &-tick {
        width: 30px;
        text-align: center;
    }

    &-name {
        flex-grow: 1;
        overflow: hidden;

        &-inner {
            display: flex;
            min-width: 0;
        }

        div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>