<template>
  <div ref="screenShareTile" class="screen-share-tile h-100 d-flex flex-column"
    :style="screenShareWrapperWidth ? { maxWidth: `${screenShareWrapperWidth}px` } : null">
    <div ref="screenShareOuter" class="screen-share-outer d-flex">
      <div ref="screenShareWrapper" class="screen-share-wrapper"
        :style="screenShareWidth ? { width: `${screenShareWidth}px` } : screenShareMaxWidth ? { maxWidth: `${screenShareMaxWidth}px` } : null">
        <div class="screen-share-inner h-100 d-flex">
          <div ref="videoEl" class="video">
            <VideoTile ref="videoTile" :audio-track="audioTrack" :video-track="videoTrack" @resize="onResize" />
            <span>{{ displayName }}</span>
            <div v-if="admin" class="screen-share-buttons">
              <CircleButton :icon-class="isPinned ? 'screen-share-pinned' : 'screen-share-button'"
                :alt="isPinned ? `This screen share is pinned` : `Click to pin this screen share`" fa-icon="thumbtack"
                @click="onPinClick" />
              <CircleButton icon-class="screen-share-button" alt="Hide screen share" fa-icon="xmark"
                @click="onHideClick" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import VideoTile from "@/components/classroom/video/VideoTile.vue";
import CircleButton from "@/components/buttons/CircleButton.vue";
import domHelpers from '@/scripts/helpers/domHelpers.js';

var resizeObservers = [],
  lastRatio = null;

const emit = defineEmits(['resize', 'pinClick', 'hideClick']);

const screenShareOuter = ref(null),
  screenShareWrapperDimensions = ref(null),
  screenShareWrapperWidth = ref(null),
  screenShareWrapper = ref(null),
  screenShareWidth = ref(null),
  videoEl = ref(null),
  videoTile = ref(null),
  screenShareMaxWidth = ref(null);

const props = defineProps({
  participant: Object,
  screenShareSettings: Object,
  admin: Boolean
});

defineExpose({
  setSize
});

watch(() => props.participant, () => { onScreenShareChange(); }, { immediate: true });

const isPinned = computed(() => {
  return props.participant.session_id === props.screenShareSettings.pinned;
})

const displayName = computed(() => {
  return props.participant.user_name + (props.participant.local ? ' (You)' : '');
});

const audioTrack = computed(() => {
  return !props.participant.local ? props.participant.tracks?.screenAudio : null;
});

const videoTrack = computed(() => {
  return props.participant.tracks?.screenVideo;
});

function onScreenShareChange() {
  if (props.participant) {
    setTimeout(() => {
      domHelpers.watchResize(resizeObservers, 'screenShareWrapper', screenShareWrapper.value, screenShareWrapperDimensions, setSize);
    }, 100);
  }
  else {
    resizeObservers['screenShareWrapper'].disconnect();
  }
}

function onResize() {
  setSize();
}

function onPinClick() {
  emit('pinClick', { participant: props.participant, width: screenShareWrapperWidth.value });
}

function onHideClick() {
  emit('hideClick');
}

function setSize() {
  var containerHeight,
    ratio,
    maxWidth;

  if (videoTile.value) {
    ratio = videoTile.value.getRatio();

    containerHeight = screenShareOuter.value.clientHeight - 20; // Reduce by 20 to make room for name label
    maxWidth = containerHeight * ratio;
    screenShareWidth.value = maxWidth;
    screenShareMaxWidth.value = maxWidth;
    screenShareWrapperWidth.value = null;

    // Force repaint
    screenShareWidth.value = null;

    setTimeout(() => {
      if (screenShareOuter.value) {
        getComputedStyle(screenShareOuter.value).height;
        setWrapperWidth();
      }
    }, 1);

    lastRatio = ratio;
  }
}

function setWrapperWidth() {
  screenShareWrapperWidth.value = Math.max(props.screenShareSettings.pinnedWidth || 0, screenShareWrapper.value.clientWidth);
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.screen-share-tile {
  margin-right: 2vh;
  flex-grow: 10;
}

.screen-share-wrapper {
  margin: 0 auto
}

.screen-share-outer {
  text-align: center;
  overflow: hidden;
  flex-grow: 1;
}

.screen-share-inner {

  video {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.video {
  position: relative;
  margin-bottom: 4vh;

  span {
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    text-align: left;
    font-size: 80%;
    margin-top: 5px;
  }
}

.screen-share-buttons {
  position: absolute;
  display: flex;
  right: 10px;
  top: 10px;
  gap: 10px;

  :deep {
    .screen-share-button {
      border: 1px solid #fff;
      background-color: rgba(0, 0, 0, 0.7);
    }

    .screen-share-pinned {
      border: 1px solid #fff;
      background-color: $blue;
    }
  }
}

:deep {
  .screen-share-active {
    background-color: $blue;
  }
}
</style>
