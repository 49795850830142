<template>
    <div class="challenges-tile d-flex flex-column">
        <ChallengeRows v-if="expectedParticipants || participants.length > 0" :participants="participants"
            :expected-participants="expectedParticipants" @challenge-changed="onChallengeChanged" />
    </div>
</template>

<script setup>
import ChallengeRows from "@/components/classroom/sidePanel/challenges/ChallengeRows.vue";

const props = defineProps({
    participants: Object,
    expectedParticipants: Object
});

const emit = defineEmits(['challengeChanged']);

function onChallengeChanged(data) {
    emit('challengeChanged', data);
}

</script>

<style lang="scss">
@import '@/styles/variables.scss';

.challenges-tile {
    padding: 2vh;
}
</style>