<template>
  <div v-if="isOpen" ref="el" class="popup" :style="position">
    <slot></slot>
  </div>
  <div v-if="shroud" class="shroud"></div>
</template>

<script setup>
import { ref, computed } from "vue";
import domHelpers from '@/scripts/helpers/domHelpers.js';
import { onClickOutside } from '@vueuse/core';

var toggleTimeout;

const el = ref(null),
  isOpen = ref(false);

const props = defineProps({
  anchor: Object,
  shroud: Boolean,
  closeOnClickOutside: Boolean
});

const position = computed(() => {
  return domHelpers.getPopupPosition(el.value, props.anchor);
});

onClickOutside(el, () => {
  if (props.closeOnClickOutside === true) {
    setTimeout(() => {
      toggle(false);
    }, 1);
  }
});

function toggle(show, duration) {
  if (toggleTimeout) {
    clearTimeout(toggleTimeout);
  }

  isOpen.value = show === true ? true : show === false ? false : !isOpen.value;

  if (duration) {
    toggleTimeout = setTimeout(() => {
      toggle(false);
    }, duration);
  }
}

defineExpose({
  toggle
});

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.popup {
  position: fixed;
  background: white;
  border-radius: 5px;
  color: $darker;
  opacity: 1;
  z-index: 1000;
  font-size: 0.8rem;
  box-shadow: $shadow;
}

.shroud {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
</style>