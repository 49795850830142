<template>
    <DeviceCheck title="Microphone Check" question="Do the bars turn white when you speak?" :devices="devices" device-name="Microphone"
        v-bind="$attrs" @deviceChange="onDeviceChange">
        <SoundChart :sound-level="microphoneLevel" />
    </DeviceCheck>
</template>
  
<script setup>
import { inject } from 'vue';
import DeviceCheck from "./DeviceCheck.vue";
import SoundChart from './SoundChart.vue';

const microphoneLevel = inject('localMicrophoneLevel');

const emit = defineEmits(['microphoneChange']);
const props = defineProps(['devices']);

function onDeviceChange(deviceId) {
    emit('microphoneChange', deviceId);
}

</script>
  
<style lang="scss" scoped></style>
  