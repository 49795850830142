<template>
    <div class="chat-tile h-100">
        <div class="chat-tile-inner d-flex flex-column h-100">
            <div ref="messagePane" class="message-pane flex-grow-1">
                <div class="messages">
                    <ChatMessage v-for="(m, i) in messages" :key="i" :message="m" :admin="admin" @delete="onDelete" />
                </div>
            </div>
            <div v-if="enabled">
                <form @submit="submitForm">
                    <div class="input">
                        <label for="message">Type a message...</label>
                        <textarea id="message" v-model="text" type="text" placeholder="Type a message..."
                            @keydown.enter="submitForm"></textarea>
                    </div>

                    <button class="submit-button" type="submit">
                        <img :src="sendIcon" alt="" />
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { ref } from 'vue';
import ChatMessage from "@/components/classroom/sidePanel/chat/ChatMessage.vue";

import sendIcon from "@/assets/send.svg";

const text = ref(''),
    messagePane = ref(null);

const props = defineProps({
    messages: Array,
    enabled: Boolean,
    admin: Boolean
});

var emit = defineEmits(['sendMessage', 'deleteMessage']);

// Send chat message using prop method from CallTile.vue
function submitForm(e) {
    e.preventDefault();

    if (text.value) {
        emit('sendMessage', text.value);
        text.value = '';
        messagePane.value.scrollTop = messagePane.value.scrollHeight + 100;
    }
}

function onDelete(id) {
    emit('deleteMessage', id);
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';

.chat-message-text {
    a {
        color: $red !important;
    }
}
</style>
  
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Ropa+Sans&display=swap");
@import '@/styles/variables.scss';


.message-pane {
    overflow: auto;
}

.messages {
    padding: 2vh 0;
    margin: 0 2vh;
}

.input {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    font-size: 0.8rem;
}

label {
    display: none;
}

.input textarea {
    width: 100%;
    padding-left: 2vh;
    font-size: 14px;
    border: none;
    resize: none;
}

.input textarea::placeholder {
    font-size: 14px;
}

form {
    display: flex;
    border-top: 2px solid $lightIndigo;
}

.submit-button {
    padding: 4px;
    margin: 0 0 0 16px;
    border: none;
    background-color: #fff;
}

.chat-message {
    color: #121a24;
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 20px;

    .chat-name {
        color: #6b7785;
    }
}

:deep {
    .delete-icon {
        border: 1px solid $darker;
    }
}

@media screen and (max-width: 700px) {
    .chat-container {
        right: calc((100% + 56px) * -1);
    }
}
</style>
  