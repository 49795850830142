<template>
  <PopUp ref="popup" :anchor="anchor" :close-on-click-outside="true">
    <ul @click="onClick">
      <slot></slot>
    </ul>
  </PopUp>
</template>

<script setup>
import { ref } from 'vue';
import PopUp from "@/components/PopUp.vue";

const popup = ref(null);

const props = defineProps({
  selectedValue: String,
  anchor: Object
});

const emit = defineEmits(['menuClick']);

function onClick() {
  toggle(false);
}

function toggle(show) {
  popup.value.toggle(show);
}

defineExpose({
  toggle
});

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

ul {
  margin-bottom: 0;
  max-width: 300px;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
</style>

