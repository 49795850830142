<template>
    <ClassroomLayout>
        <div class="message">
            <h3>We're having trouble connecting you to your lesson.</h3>
            <p>
                There might be a problem with your internet connection. Click the 'retry' button to try again.
            </p>
            <p>
                <Button label="Retry" @click="reload"></Button>
            </p>
            <p>&nbsp;</p>
            <p>
                If you continue to have problems joining your lesson, please <a
                    href="https://www.cococoders.com/contact-us">get in touch</a> and we'll
                help to resolve the issue.
            </p>
        </div>
    </ClassroomLayout>
</template>

<script setup>

import ClassroomLayout from "@/components/classroom/ClassroomLayout.vue";
import Button from "@/components/buttons/Button.vue";

function reload() {
    location.reload();
}

</script>

<style lang="scss" scoped>
.message {
    text-align: center;

    h3 {
        margin-bottom: 50px;
    }

    p {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>
