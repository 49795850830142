<template>
  <div :class="['side-panel white', isOpen ? 'open' : '']">
    <button class="side-panel-button" @click="toggle">
      <img class="icon" :src="isOpen ? doubleChevronRightIcon : doubleChevronLeftIcon" alt="" />
      <div v-if="notificationCount && notificationCount > 0" class="notification-count">
        {{ notificationCount > 99 ? '99+' : notificationCount }}
      </div>
    </button>
    <div class="side-panel-inner">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import doubleChevronLeftIcon from "@/assets/doubleChevronLeft.png";
import doubleChevronRightIcon from "@/assets/doubleChevronRight.png";


const emit = defineEmits(['open', 'close']);

const props = defineProps({
  notificationCount: Number
})

const isOpen = ref(false);

defineExpose({ toggle, isOpen });

function toggle(show) {
  var current = isOpen.value;
  isOpen.value = show === true ? true : show === false ? false : !isOpen.value;

  // Emit event if state has changed
  if (current !== isOpen.value) {
    emit(isOpen.value ? 'open' : 'close');
  }
}

</script>

<style lang="scss">
@import '@/styles/variables.scss';

.side-panel {
  p {
    color: $darker
  }
}
</style>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.side-panel {
  flex-basis: 0;
  transition: all 0.3s;

  position: relative;
  padding-right: 0;
  padding-left: 0;

  &-inner {
    position: absolute;
    width: 300px;
    left: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
  }

  &.open {
    flex-basis: 300px;
  }

  &-button {
    border: none;
    cursor: pointer;
    padding: 10px;
    background: #fff;
    position: absolute;
    left: -40px;
    top: 27px;
    height: 44px;
    width: 44px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    transition: all 0.3s;

    .notification-count {
      background: $red;
      position: absolute;
      left: -1.2vh;
      bottom: -1.2vh;
      border-radius: 50%;
      line-height: 2.4vh;
      display: inline-block;
      width: 2.4vh;
      height: 2.4vh;
      font-size: 1vh;
      color: white;
      animation: boing 300ms ease-in-out;
    }

    &:hover {
      transform: scale(1.1)
    }

    img {
      max-width: 100%;
    }
  }
}</style>
