export default {
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    weekdays: { 'Monday': 0, 'Tuesday': 1, 'Wednesday': 2, 'Thursday': 3, 'Friday': 4, 'Saturday': 5, 'Sunday': 6 },

    getFullDateString: function (date, includeYear = true) {
        var result,
            weekday = this.getWeekdayName(date),
            year = date.getFullYear(),
            month = this.getMonthName(date),
            day = date.getDate();

        result = weekday + ' ' + month + ' ' + day;
        if (includeYear) {
            result += ', ' + year;
        }

        return result;
    },

    getWeekdayName: function (date) {
        var result,
            weekday,
            weekdays;

        weekday = (date.getDay() || 7) - 1;

        weekdays = Object.keys(this.weekdays);
        for (var x = 0; x < weekdays.length; x++) {
            if (this.weekdays[weekdays[x]] == weekday) {
                result = weekdays[x];
                break;
            }
        }

        return result;
    },

    getMonthName: function (date) {
        return this.monthNames[date.getMonth()];
    }
}