<template>
  <PopUp ref="popup" :anchor="anchor">
    <div class="tooltip"><span v-if="title">{{ title }}</span>{{ message }}</div>
  </PopUp>
</template>

<script setup>
import { onUnmounted, ref, watch } from "vue";
import PopUp from "@/components/PopUp.vue";

const popup = ref(null);

const props = defineProps({
  message: String,
  title: String,
  anchor: Object
});

onUnmounted(() => {
  removeMouseEvents(props.anchor);
})

watch(() => props.anchor, (now, then) => {
  if (then) {
    removeMouseEvents(then);
  }

  if (now) {
    addMouseEvents(now);
  }
});

function addMouseEvents(el) {
  el.addEventListener("mouseover", onMouseOver);
  el.addEventListener("mouseleave", onMouseLeave);
}

function removeMouseEvents(el) {
  if (el) {
    el.removeEventListener("mouseover", onMouseOver);
    el.removeEventListener("mouseleave", onMouseLeave);
  }
}

function onMouseOver() {
  toggle(true);
}

function onMouseLeave() {
  toggle(false);
}

function toggle(show, duration) {
  popup.value.toggle(show, duration);
}

defineExpose({
  toggle
});

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.tooltip {
  max-width: 250px;
  opacity: 1;
  padding: 8px 15px;

  span {
    font-weight: bold;
    display: block;
  }
}
</style>
