<template>
  <div class="stretch-center waiting">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid">
      <circle cx="40" cy="40" r="32" stroke-width="8" stroke="#8c85cd"
        stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round"
        style="animation-play-state: running; animation-delay: 0s;">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="3.4482758620689653s"
          keyTimes="0;1" values="0 40 40;360 40 40" style="animation-play-state: running; animation-delay: 0s;">
        </animateTransform>
      </circle>
    </svg>
  </div>
</template>

<script>

export default {
  name: "WaitingAnimation"
};
</script>