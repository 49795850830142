<template>
  <li :class="[selected ? 'on' : '']" @click="onClick">
    <slot></slot>
    {{ label }}
  </li>
</template>

<script setup>

const props = defineProps({
  selected: Boolean,
  value: String,
  label: String,
  newSection: Boolean
});

const emit = defineEmits(['click']);

function onClick() {
  emit('click', props.value);
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

li {
  color: $darker;
  padding: 8px 20px;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  cursor: pointer;
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 0;

  &.on {
    font-weight: bold;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1)
  }
}
</style>

