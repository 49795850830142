<template>
  <div ref="sidePanelSection" :class="['side-panel-section', collapsible ? 'collapsible' : 'fixed']">
    <div v-if="title" class="side-panel-section-title" @click="onClick">
      <i v-if="faIcon" :class="['fa', 'fa-' + faIcon]"></i>{{
        title }}
    </div>
    <Transition>
      <div ref="sidePanelSectionInner" class="side-panel-section-inner">
        <slot></slot>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useEventBus } from '@vueuse/core';

const bus = useEventBus('sidePanelSection-open')

const emit = defineEmits(['expand', 'collapse']);

const props = defineProps({
  collapsible: { type: Boolean, default: true },
  openByDefault: Boolean,
  title: String,
  faIcon: String
});

const isOpen = ref(false),
  sidePanelSectionInner = ref(null),
  sidePanelSection = ref(null);

onMounted(() => {
  bus.on((key, el) => { onSidePanelOpened(el) });
  isOpen.value = props.openByDefault || !props.collapsible;
  if (isOpen.value) {
    expand();
  }
  else {
    collapse();
  }
});

function onClick(e) {
  if (props.collapsible) {
    isOpen.value = !isOpen.value;
  }

  if (isOpen.value) {
    expand();
    bus.emit('sidePanelSection-open', sidePanelSection.value);
  }
  else {
    collapse();
    emit('collapse');
  }
}

function onSidePanelOpened(el) {
  if (props.collapsible && isOpen.value && el !== sidePanelSection.value) {
    isOpen.value = false;
    collapse();
  }
}

function expand() {
  var targetHeight,
    el;

  if (props.collapsible) {
    el = sidePanelSectionInner.value;

    el.style.display = 'block';
    el.style.visibility = 'hidden';
    el.style.position = 'absolute';
    el.style.height = 'auto';

    targetHeight = getComputedStyle(el).height;

    // Force repaint
    getComputedStyle(el).height;

    el.style.display = null;
    el.style.visibility = null;
    el.style.position = null;
    el.style.height = 0;

    // Trigger the animation
    requestAnimationFrame(() => {
      el.style.height = targetHeight;
      setTimeout(() => {
        el.style.height = null;
      }, 300);
    });
  }
}

function collapse() {
  var el;

  if (props.collapsible) {
    el = sidePanelSectionInner.value;
    el.style.height = getComputedStyle(el).height;

    // Force repaint
    getComputedStyle(el).height;

    // Trigger the animation
    requestAnimationFrame(() => {
      el.style.height = 0;
    });
  }
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.side-panel-section {
  border-bottom: 1px solid $lightIndigo;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  font-size: 0.8rem;
  min-height: 4vh;

  &.fixed {
    flex-grow: 1;
    min-height: 25vh;

    .side-panel-section-inner {
      position: absolute;
      top: 4vh;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }

  &-title {
    padding: 0 15px;
    font-size: 0.8rem;
    line-height: 4vh;
    color: $indigo;
    background: $lighterIndigo;
    font-weight: bold;
    cursor: pointer;

    i {
      margin-right: 0.8vh;
      font-size: 1.2rem;
    }
  }

  &.locked {
    .side-panel-section-inner {
      flex-shrink: 10;
    }
  }

  &-inner {
    overflow: auto;
    transition: all 0.3s;
  }
}
</style>
