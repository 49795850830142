<template>
  <div class="classroom dark h-100 d-flex theme-background"
    :style="props.theme ? { backgroundImage: themeBackground } : null">
    <ConfettiExplosion v-if="confetti" :stage-height="1200" :colors="['#8c85cd', '#ff004d', '#ffd300']" />
    <div class="classroom-main d-flex h-100 flex-column">
      <div class="classroom-header">
        <div v-if="slots.back" class="backWrapper">
          <slot name="back"></slot>
        </div>
        <div class="logo">
          <img :src="logoDarkLandscape" />
        </div>
      </div>
      <div class="classroom-body d-flex flex-column">
        <slot></slot>
      </div>
      <slot name="controls"></slot>
    </div>
    <slot name="sidePanel"></slot>
  </div>
</template>

<script setup>
import logoDarkLandscape from "@/assets/logo-dark-landscape.png";
import ConfettiExplosion from "vue-confetti-explosion";
import { computed } from "vue";
import { useSlots } from 'vue'
const slots = useSlots()

const props = defineProps({
  theme: String,
  confetti: Boolean
});

const themeBackground = computed(() => {
  return `url("/assets/theme-backgrounds/${props.theme}.svg")`;
});
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.classroom {
  overflow: hidden;

  &-main {
    flex: 1;
  }

  &-body {
    justify-content: center;
    overflow: auto;
    flex-grow: 1;
    margin: 0 2vh;
  }
}

.logo {
  padding: 2vh 0 1vh 0;
  text-align: center;
  margin: 0 auto;

  >img {
    width: 15vh;
  }
}

.backWrapper {
  position: absolute;
  left: 10px;
  top: 10px;
}

:deep {
  .confetti-container {
    position: absolute;
    left: 50%;
  }
}
</style>
