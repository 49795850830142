<template>
  <div :class="['call-controls', devices ? 'with-devices' : null]">
    <div class="col call-controls-set call-controls-data">
      <p>
        {{ lessonData.time }}<br>{{ formattedLessonDate }}<br>{{ lessonData.productName }}<br>{{ lessonData.contentType
        }}
      </p>
    </div>
    <div class="col-6 call-controls-set call-controls-set-start">
      <CallControl icon-class="call-control-microphone" label="Microphone" :fa-icon="microphoneIcon"
        :devices="microphoneDevices" @click="onAudioClick" @deviceClick="onMicrophoneMenuClick" />
      <CallControl icon-class="call-control-camera" label="Camera" :fa-icon="cameraIcon" :devices="cameraDevices"
        @click="onVideoClick" @deviceClick="onCameraMenuClick" />
      <CallControl v-if="supportsScreenShare && enableScreenShare" icon-class="call-control-screen-share"
        fa-icon="display" label="Share" @click="onScreenShareClick" />
      <CallControl v-if="enableReact" ref="reactButton" icon-class="call-control-react" fa-icon="face-smile"
        label="React" @click="onReactClick" />
      <PopupMenu v-if="enableReact" ref="reactMenu" :anchor="reactButton?.el">
        <PopupMenuIcon v-for="e in emojis" :key="e.key" :value="e.key" :fa-icon="e.faIcon" @click="onEmojiClick" />
        <PopupMenuBreak />
        <PopupMenuIcon value="hand" fa-icon="hand" :new-section="true" @click="onHandClick" />
      </PopupMenu>
    </div>
    <div class="col call-controls-set call-controls-set-end">
      <CallControl fa-icon="phone-slash" icon-class="call-control-leave" label="Leave" @click="onLeaveClick" />
    </div>
    <ModalMessage ref="modalMessage" />
  </div>
</template>

<script setup>
import daily from "@daily-co/daily-js";
import { ref, onMounted, computed } from 'vue';
import _ from 'lodash';
import CallControl from "./CallControl.vue";
import PopupMenu from "@/components/menus/PopupMenu.vue";
import PopupMenuIcon from "@/components/menus/PopupMenuIcon.vue";
import PopupMenuBreak from "@/components/menus/PopupMenuBreak.vue";
import ModalMessage from "@/components/ModalMessage.vue";
import emojis from '@/data/emojis';

const supportsScreenShare = ref(false),
  reactButton = ref(null),
  reactMenu = ref(null),
  modalMessage = ref(null);

const props = defineProps({
  participant: Object,
  enableScreenShare: Boolean,
  enableReact: Boolean,
  devices: Object,
  admin: Boolean,
  lessonData: Object
});

const cameraDevices = computed(() => {
  // Clone to ensure reactivity
  return _.clone(props.devices?.camera);
});

const microphoneDevices = computed(() => {
  // Clone to ensure reactivity
  return _.clone(props.devices?.microphone);
});

const microphoneIcon = computed(() => {
  return props.participant.audio ? 'microphone' : 'microphone-slash';
});

const cameraIcon = computed(() => {
  return props.participant.video ? 'video' : 'video-slash';
});

const formattedLessonDate = computed(() => {
  return props.lessonData.date;
});

const emit = defineEmits(['videoClick', 'audioClick', 'screenShareClick', 'leaveClick', 'cameraChange', 'microphoneChange', 'speakerChange', 'emojiClick', 'handClick']);

onMounted(() => { setupControls() });

async function setupControls() {
  // Only show the screen share button if the browser supports it
  supportsScreenShare.value = daily.supportedBrowser().supportsScreenShare;
}

function onAudioClick(e) {
  emit('audioClick', e);
}

function onMicrophoneMenuClick(deviceId) {
  emit(`microphoneChange`, deviceId);
}

function onVideoClick(e) {
  emit('videoClick', e);
}

function onCameraMenuClick(deviceId) {
  emit(`cameraChange`, deviceId);
}

function onScreenShareClick(e) {
  emit('screenShareClick', e);
}

function onReactClick(e) {
  reactMenu.value.toggle();
}

function onEmojiClick(key) {
  emit('emojiClick', key);
}

function onHandClick(key) {
  emit('handClick', key);
}

function onLeaveClick(e) {
  if (!props.admin) {
    emit('leaveClick', e);
  }
  else {
    modalMessage.value.show({
      message: `This will end the lesson for all participants`,
      buttons: [{ label: 'Cancel', type: 'secondary' }, { label: 'OK', onClick: () => { emit('leaveClick', e); } }]
    });
  }
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.call-controls {
  padding: 3vh 0 1vh 0;
  display: flex;

  &.with-devices {
    padding-bottom: 6vh;
  }

  &-set {
    padding: 0 30px;
    display: flex;
    justify-content: center;
    gap: 30px;
  }

  &-start {
    justify-content: flex-start;
  }

  &-end {
    justify-content: flex-end;
  }

  &-data {
    font-size: 0.8rem;
  }
}

:deep {
  .call-control {
    &-microphone {
      background-color: $blue;
    }

    &-camera {
      background-color: $orange;
    }

    &-screen-share {
      background-color: $purple;
    }

    &-react {
      background-color: $green;
    }

    &-leave {
      background-color: $red;
    }
  }
}
</style>
