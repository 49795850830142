<template>
  <ClassroomLayout>
    <div ref="joinFrame" class="d-flex flex-column join-frame"
      :style="{ maxHeight: parentHeight ? `${parentHeight}px` : '' }">
      <p>{{ message }}...</p>
      <ParticipantVideo ref="videoTile" :participant="participant" class="join-frame-video-tile" @mute="onMuteClick" />
      <p>Ready?</p>
      <div>
        <div class="button button-big" @click="joinClick">Join</div>
      </div>
    </div>

    <template #controls>
      <CallControls :enable-screen-share="false" :participant="participant" :devices="devices" v-bind="$attrs">
      </CallControls>
    </template>
  </ClassroomLayout>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import ParticipantVideo from "./video/ParticipantVideo.vue";
import CallControls from "@/components/classroom/CallControls.vue";
import ClassroomLayout from "@/components/classroom/ClassroomLayout.vue";

var parentEl,
  parentElObserver;

const messages = ['Test out your smile', 'Check your hair', 'Make sure your room is tidy'];

const emit = defineEmits(['joinCall', 'muteClick']);

const props = defineProps({
  participant: Object,
  devices: Object
});

const joinFrame = ref(null),
  parentHeight = ref(null),
  message = ref(null);

onMounted(() => {
  recordParentHeight();
  setMessage();
});

onUnmounted(() => {
  parentElObserver.disconnect();
  parentElObserver = null;
});


function setMessage() {
  message.value = messages[Math.floor(Math.random() * messages.length)];
}

function recordParentHeight() {
  parentEl = parentEl || joinFrame.value.parentElement;
  if (!parentElObserver) {
    parentElObserver = new ResizeObserver(recordParentHeight);
    parentElObserver.observe(parentEl);
  }

  parentHeight.value = parentEl.clientHeight;
}

function joinClick() {
  emit('joinCall');
}

function onMuteClick() {
  emit('muteClick', props.participant);
}

</script>

<style lang="scss" scoped>
.join-frame {
  margin: 0 auto;
  min-width: 300px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  padding: 3vh 0;

  &-video-tile {
    width: 100%;
    margin: 2vh auto 5vh auto;
    max-height: calc(100% - 90px);
  }

}
</style>
