<template>
    <PopupMenu v-if="devices" ref="menu" :anchor="anchor">
        <PopupMenuItem v-for="d in devices" :key="d.deviceId" :value="d.deviceId" :selected="d.deviceId === activeDeviceId"
            :label="d.label" @click="onMenuClick" />
    </PopupMenu>
</template>
  
<script setup>
import { ref, computed } from 'vue';
import PopupMenu from "@/components/menus/PopupMenu.vue";
import PopupMenuItem from "@/components/menus/PopupMenuItem.vue";
import _ from 'lodash';

const emit = defineEmits(['deviceClick']);

const props = defineProps(['devices', 'anchor']);

defineExpose({
    toggle
});

const menu = ref(null);

const activeDeviceId = computed(() => {
    var activeDevice;

    activeDevice = _.find(props.devices, (d) => { return d.active ? true : false; });

    return activeDevice?.deviceId;
});

function onMenuClick(deviceId) {
    emit('deviceClick', deviceId);
}

function toggle() {
    menu.value.toggle();
}
</script>
  
<style lang="scss" scoped></style>
  