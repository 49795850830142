<template>
  <div ref="el" :class="['button', size ? `button-${size}` : '']" :title="alt" @click="onClick">{{ label }}</div>
</template>

<script setup>
import { computed, ref } from 'vue';

const el = ref(null);

const props = defineProps({
  label: String,
  size: String,
  alt: String
});

const emit = defineEmits(['click']);

defineExpose({ el });

function onClick(e) {
  emit('click', e);
}

</script>

<style lang="scss" scoped>

</style>
