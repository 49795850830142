<template>
    <ClassroomLayout>
        <div class="classroom-lobby">
            <h3>Your teacher will let you in soon</h3>
        </div>
        <CountdownTimer v-if="countingDown" :time="timeToStart" @ended="onCountdownEnded"></CountdownTimer>
    </ClassroomLayout>
</template>

<script setup>

import ClassroomLayout from "@/components/classroom/ClassroomLayout.vue";
import CountdownTimer from "@/components/CountdownTimer.vue";
import { onMounted, ref } from "vue";

const props = defineProps({
    timeToStart: Number
});

const countingDown = ref(false);

onMounted(() => {
    countingDown.value = (props.timeToStart && props.timeToStart > 0) ? true : false;
})

function onCountdownEnded() {
    countingDown.value = false;
}
</script>

<style lang="scss" scoped>
.classroom-lobby {
    text-align: center;

    h3 {
        margin-bottom: 50px
    }
}
</style>
