<template>
  <PopUp v-if="modalContent" ref="popup" :anchor="anchor" :shroud="true">
    <div class="modal-message white">
      <h2 v-if="modalContent?.title">{{ modalContent.title }}</h2>
      <p v-html="modalContent.message"></p>
      <div v-if="modalContent?.buttons" class="modal-message-buttons">
        <div v-for="b in modalContent.buttons" :key="b.key" :class="['button', b.type ? b.type : '']"
          @click="handleClick(b.onClick)">
          {{ b.label }}
        </div>
      </div>
    </div>
  </PopUp>
</template>

<script setup>
import { ref } from "vue";
import PopUp from "@/components/PopUp.vue";

const popup = ref(null),
  modalContent = ref(null);

const props = defineProps({
  content: Object,
  anchor: Object
});

function toggle(show, duration) {
  popup.value.toggle(show, duration);
}

function show(content, duration) {
  modalContent.value = content;

  setTimeout(() => {
    toggle(true, duration);
  }, 1);
}

function close() {
  toggle(false);
  modalContent.value = null;
}

function handleClick(fn) {
  if (fn) {
    fn();
  }
  else {
    close();
  }
}

defineExpose({
  toggle,
  show
});

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.modal-message {
  max-width: 400px;
  min-width: 200px;
  opacity: 1;
  position: relative;
  display: block;
  padding: 3vh 4vh 2vh 4vh;
  background: transparent;

  &-buttons {
    display: block;
    text-align: center;
    padding-top: 2vh;
  }

  h2 {
    font-weight: bold;
    font-size: 1rem;
    padding-bottom: 2vh;
  }
}
</style>
