<template>
    <ClassroomLayout>
        <div ref="$deviceCheckFrame" class="check-frame d-flex flex-column"
            :style="{ visibility: parentHeight ? 'visible' : 'hidden', maxHeight: parentHeight ? `${parentHeight}px` : '' }">
            <CameraCheck v-if="currentDeviceType === 'camera'" :devices="currentDevices" :participant="participant"
                @cameraChange="onCameraChange" @yesClick="onYesClick" @noClick="showAdvice" @skipClick="onSkipClick" />
            <MicrophoneCheck v-else-if="currentDeviceType === 'microphone'" :devices="currentDevices"
                @microphoneChange="onMicrophoneChange" @yesClick="onYesClick" @noClick="showAdvice"
                @skipClick="onSkipClick" />
            <SpeakerCheck v-else-if="currentDeviceType === 'speaker'" :devices="currentDevices"
                @speakerChange="onSpeakerChange" @yesClick="onYesClick" @noClick="showAdvice" @skipClick="onSkipClick" />

            <NetworkCheck v-show="currentDeviceType === 'network'" :participant="participant"
                @runNetworkCheck="runNetworkCheck" @complete="onNetworkCheckComplete" />

            <div v-if="!currentDeviceType" class="check-results">
                <h3>
                    {{ failedCount === 0 ? `You're all set for your next lesson` : `Your device failed on ${failedCount}
                                        check${failedCount > 1 ? 's' : ''}` }}
                </h3>
                <ul>
                    <li v-for="(device, i) in deviceTypes" :key="i" class="d-flex">
                        <CircleButton :fa-icon="device.icon" :icon-class="`check-${device.status.toLowerCase()}`"
                            :class="`device-check-${device.status.toLowerCase()}`" :static="true" size="large"
                            @click="reloadCheck(i)" />
                        <div class="check-result">
                            <div>
                                <div class="check-status">
                                    {{ device.title }}: {{ device.status }}
                                </div>
                                <div v-if="device.message" class="check-message">
                                    {{ device.message }}
                                </div>
                                <div v-if="device.status != 'Passed'" class="button secondary" href="#"
                                    @click="reloadCheck(i)">
                                    Try again
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div v-if="hasLesson" class="button button-big check-results-join" @click="onJoinClick">
                    Join Lesson
                </div>
            </div>
        </div>

        <template #sidePanel>
            <SidePanel v-if="currentAdvice" ref="sidePanel">
                <SidePanelSection :collapsible="false">
                    <div class="advice">
                        <h3>{{ currentAdvice.title }}</h3>
                        <p v-if="currentAdvice.subtitle">{{ currentAdvice.subtitle }}</p>
                        <ol v-if="currentAdvice.tips" class="tips">
                            <li v-for="(tip, i) in currentAdvice.tips" :key="i">
                                <p class="tip-title">{{ tip.title }}</p>
                                <p v-if="tip.body" class="tip-note">{{ tip.body }}</p>
                                <ul v-if="tip.links">
                                    <li v-for="(link, j) in tip.links" :key="j">
                                        <a :href="link.url" target="_blank">{{
                                            link.title }}</a>
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </SidePanelSection>
            </SidePanel>
        </template>
    </ClassroomLayout>
</template>
  
<script setup>
import { onMounted, onUnmounted, ref, computed, inject } from 'vue';
import CameraCheck from "./CameraCheck.vue";
import MicrophoneCheck from "./MicrophoneCheck.vue";
import SpeakerCheck from "./SpeakerCheck.vue";
import NetworkCheck from "./NetworkCheck.vue";
import ClassroomLayout from "@/components/classroom/ClassroomLayout.vue";
import CircleButton from "@/components/buttons/CircleButton.vue";
import SidePanel from "@/components/classroom/sidePanel/SidePanel.vue";
import SidePanelSection from "@/components/classroom/sidePanel/SidePanelSection.vue";
import _ from 'lodash';
import { useCookies } from "vue3-cookies";

const deviceTypes = [
    {
        type: 'camera',
        title: 'Camera Check',
        icon: 'video',
        advice: {
            title: 'Camera not working?',
            subtitle: 'Here are a few things to try...',
            tips: [
                { title: 'Check the right camera is selected', body: `You might have more than one camera on your device. To check the right one is selected, click the 'Change Camera' button and choose your preferred camera.` },
                { title: 'Check no other apps are using the camera', body: `If another app is also using your camera (such as Zoom or Skype), that can stop it working in our classroom. Close down any other apps using your camera and/or restart your device to fix the issue.` },
                { title: 'Check your device permissions', body: `Your device might be preventing your web browser from using your camera. You should check that your web browser (e.g. Chrome, Safari, Edge) is included in the allowed list. Select your operating system to see instructions:`, links: [{ title: 'Windows', url: 'https://support.microsoft.com/en-us/windows/manage-app-permissions-for-your-camera-in-windows-87ebc757-1f87-7bbf-84b5-0686afb6ca6b' }, { title: 'Mac OS', url: 'https://support.apple.com/en-gb/guide/mac-help/mchlf6d108da/mac' }] },
                { title: `Check browser permissions`, body: `Your web browser needs to grant permission for our classroom to use your camera. Choose your browser to see instructions for enabling camera permissions:`, links: [{ title: 'Google Chrome', url: 'https://support.google.com/chrome/answer/2693767?hl=en-GB&co=GENIE.Platform%3DDesktop' }, { title: 'Firefox', url: 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions' }, { title: `Safari`, url: `https://support.apple.com/en-gb/guide/mac-help/mchlf6d108da/mac` }, { title: 'Edge', url: `https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857#:~:text=In%20Microsoft%20Edge%2C%20select%20Settings,Permissions%2C%20select%20Camera%20or%20Microphone.` }] },
                { title: 'Check your camera is connected', body: `If you are using an external camera that plugs into your computer, make sure it's plugged in and switched on, and try a different USB port if necessary.` }
            ]
        }
    },
    {
        type: 'microphone',
        title: 'Microphone Check',
        icon: 'microphone',
        advice: {
            title: 'Microphone not working?',
            subtitle: 'Here are a few things to try...',
            tips: [
                { title: 'Check the right microphone is selected', body: `You might have more than one microphone on your device. To check the right one is selected, click the 'Change Microphone' button and choose your preferred microphone.` },
                { title: 'Check no other apps are using the microphone', body: `If another app is also using your microphone (such as Zoom or Skype), that can stop it working in our classroom. Close down any other apps using your microphone and/or restart your device to fix the issue.` },
                { title: 'Check your device permissions', body: `Your device might be preventing your web browser from using your microphone. You should check that your web browser (e.g. Chrome, Safari, Edge) is included in the allowed list. Select your operating system to see instructions:`, links: [{ title: 'Windows', url: 'https://support.microsoft.com/en-us/windows/turn-on-app-permissions-for-your-microphone-in-windows-10-94991183-f69d-b4cf-4679-c98ca45f577a' }, { title: 'Mac OS', url: 'https://support.apple.com/en-gb/guide/mac-help/mchla1b1e1fe/mac' }] },
                { title: `Check browser permissions`, body: `Your web browser needs to grant permission for our classroom to use your microphone. Choose your browser to see instructions for enabling microphone permissions:`, links: [{ title: 'Google Chrome', url: 'https://support.google.com/chrome/answer/2693767?hl=en-GB&co=GENIE.Platform%3DDesktop' }, { title: 'Firefox', url: 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions' }, { title: `Safari`, url: `https://support.apple.com/en-gb/guide/mac-help/mchla1b1e1fe/mac` }, { title: 'Edge', url: `https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857#:~:text=In%20Microsoft%20Edge%2C%20select%20Settings,Permissions%2C%20select%20Camera%20or%20Microphone.` }] },
                { title: 'Check your microphone is connected', body: `If you are using an external microphone that plugs into your computer, make sure it's plugged in and switched on, and try a different USB port if necessary.` },
            ]
        }
    },
    {
        type: 'speaker',
        title: 'Speaker Check',
        icon: 'volume-high',
        advice: {
            title: 'Speakers not working?',
            subtitle: 'Here are a few things to try...',
            tips: [{ title: 'Check the right speaker is selected', body: `You might have more than one speaker (or headphones) on your device. To check the right one is selected, click the 'Change Speaker' button and choose your preferred speaker.` },
            { title: 'Make sure your volume is turned up', body: `Check that the sound on your device isn't muted, and is turned up to an audible level` },
            { title: 'Check your speakers are connected', body: `If you are using external speakers or headphones that plug into your computer, make sure they're plugged in and switched on, and try a different USB port if necessary.` }
            ]
        }
    },
    // {
    //     type: 'network',
    //     title: 'Connection Check',
    //     icon: 'wifi',
    //     message: null
    // }
];

var parentEl,
    parentElObserver;

const currentIndex = ref(0),
    sidePanel = ref(null),
    failedCount = ref(0),
    hasLesson = inject('hasLesson'),
    parentHeight = ref(null),
    $deviceCheckFrame = ref(null),
    { cookies } = useCookies();

const emit = defineEmits(['joinCall', 'cameraChange', 'microphoneChange', 'speakerChange', 'runNetworkCheck']);

const props = defineProps({
    height: Number,
    participant: Object,
    devices: Object
});

onMounted(() => {
    recordParentHeight();
    countFailed();
});

onUnmounted(() => {
    parentElObserver.disconnect();
    parentElObserver = null;
});

const currentDeviceType = computed(() => {
    return getCurrentDevice()?.type;
});

const currentDevices = computed(() => {
    return getCurrentDevices();
});

const currentAdvice = computed(() => {
    return getCurrentDevice()?.advice;
});

function getCurrentDevices() {
    return _.clone(props.devices[getCurrentDevice()?.type]);
}

function getCurrentDevice() {
    return deviceTypes[currentIndex.value];
}

function getActiveDevice(type) {
    var typeDevices;

    typeDevices = props.devices[type];
    return _.find(typeDevices, (d) => { return d.active ? true : false; });
}

function onYesClick() {
    rememberActiveDevice();
    setStatus('Passed');
    showNextDeviceCheck();
    sidePanel.value.toggle(false);
}

// Store the working device as the active device
// to ensure it is that device used in a call
function rememberActiveDevice() {
    var activeDevice,
        currentDeviceType;

    currentDeviceType = getCurrentDevice().type;

    // Only do this for specific device types
    if (['speaker', 'camera', 'microphone'].includes(currentDeviceType)) {
        activeDevice = getActiveDevice(currentDeviceType);
        if (activeDevice) {
            localStorage[currentDeviceType] = activeDevice.deviceId;
        }
    }
}

function onSkipClick() {
    setStatus('Skipped');
    showNextDeviceCheck();
    sidePanel.value.toggle(false);
}

function onJoinClick() {
    emit('joinCall', { deviceCheckResults: getDeviceCheckResults() });
}

function getDeviceCheckResults() {
    var result = { devices: [] };

    result.date = new Date();
    result.now = true;

    for (var device of deviceTypes) {
        result.devices.push({
            device: device.type,
            status: device.status
        });
    }

    return result;
}

function storeDeviceCheckResults() {
    var results;

    results = getDeviceCheckResults();
    cookies.set('deviceCheck', JSON.stringify(results), '1m');
}

function showAdvice() {
    sidePanel.value.toggle(true);
}

function setStatus(status, message = null, deviceType = null) {
    var device;

    if (deviceType) {
        device = _.find(deviceTypes, (d) => { return d.type === deviceType });
    }
    else {
        device = getCurrentDevice();
    }

    if (device) {
        device.status = status;
        device.message = message;
    }
}

function countFailed() {
    var failed = 0;

    deviceTypes.forEach((device) => {
        if (device.status == 'Failed') {
            failed++;
        }
    });

    failedCount.value = failed;
}

function showNextDeviceCheck() {
    var nextIndex;

    // Only move on if the current check already has a status
    // This allows for the network check, which completes in the background
    if (deviceTypes[currentIndex.value]?.status) {
        for (nextIndex = currentIndex.value + 1; nextIndex <= deviceTypes.length; nextIndex++) {
            if (!deviceTypes[nextIndex]?.status) {
                break;
            }
        }

        currentIndex.value = nextIndex;

        // If that was the last device, store the results in a cookie
        if (nextIndex >= deviceTypes.length) {
            countFailed();
            storeDeviceCheckResults();
        }
    }
}

function reloadCheck(index) {
    currentIndex.value = index;
}

function recordParentHeight() {
    parentEl = parentEl || $deviceCheckFrame.value.parentElement;
    if (!parentElObserver) {
        parentElObserver = new ResizeObserver(recordParentHeight);
        parentElObserver.observe(parentEl);
    }

    parentHeight.value = parentEl.clientHeight;
}

function onCameraChange(deviceId) {
    emit('cameraChange', deviceId);
}

function onMicrophoneChange(deviceId) {
    emit('microphoneChange', deviceId);
}

function onSpeakerChange(deviceId) {
    emit('speakerChange', deviceId);
}

function runNetworkCheck(callback) {
    emit('runNetworkCheck', callback);
}

function onNetworkCheckComplete(data) {
    setStatus(data.status, data.message, 'network');
    showNextDeviceCheck();
}

</script>
  
<style lang="scss">
@import '@/styles/variables.scss';

.check-question {
    margin: 5vh 0;

    &-buttons {
        .button {
            margin: 10px;
            transition: all .2s ease-in-out;
        }
    }
}

.check-passed {
    background-color: $green;
}

.check-warning,
.check-skipped {
    background-color: $orange;
}

.check-failed {
    background-color: $red;
}

.check-content {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    width: 100%;
    aspect-ratio: 16/9;
    max-width: 500px;
    text-align: center;
    margin: 0 auto;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    overflow: hidden;
}
</style>
<style lang="scss" scoped>
@import '@/styles/variables.scss';

.check-frame {
    margin: 0 auto;
    min-width: 300px;
    text-align: center;
    padding: 5vh 0;
    width: 100%;
    max-width: 500px;

    &-video-tile {
        margin: 0 auto;
        max-height: calc(100% - 90px);
    }

    .device-check {

        .button {
            margin-top: 30px;
        }
    }
}

.advice {
    margin-top: 5vh;
    margin-left: 3vh;

    h3 {
        font-size: 1.2rem;
    }

    ol {
        margin-top: 2vh;
        margin-bottom: 10vh;
        padding-left: 2rem;
        padding-right: 2rem;

        .tip-title {
            font-weight: bold;
            margin-bottom: 5px;
        }

        ul {
            margin-bottom: 2vh
        }
    }
}

.check-results {

    h3 {
        margin-bottom: 5vh;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin-left: 5vh;

        li {
            text-align: left;
            margin: 3vh 0;
        }

        .check-result {
            margin-left: 15px;

            >div {
                .check-status {
                    font-size: 1rem;
                    line-height: 60px;
                }

                .check-message {
                    font-style: italic;
                    margin-bottom: 1vh;
                    max-width: 500px;
                }

                .button {
                    display: inline-block;
                    margin-left: 30px;
                    line-height: normal;
                }
            }
        }
    }
}

.check-results-join {
    margin-top: 5vh;
    margin-bottom: 5vh;
}
</style>
  