<template>
  <div style="display: flex; flex-direction: column;"
    :class="['journey-section', `journey-section-${section.size}`, `journey-section-${section.side}`, `journey-section-${section.type}`, odd ? 'odd' : 'even', allLocked && !section.isFirst ? 'locked' : '']">
    <div :class="['journey-section-background theme-background', odd ? ' journey-section-background-odd' : '']"
      :style="{ backgroundImage: themeBackground }"></div>
    <div class="journey-section-inner">
      <JourneyArc v-for="(arc, i) in arcs" :key="i" :side="arc.side"></JourneyArc>
      <JourneyLevelBanner v-if="section.level" :level="section.level" />
      <div v-if="title" class="journey-section-title">
        <p>{{ title }}</p>
      </div>
      <Badge v-if="(anyLocked || anyAttended) && badge?.imageUrl" :badge="badge" />
      <div class="journey-dots">
        <JourneyDot v-for="(lesson, j) in lessons" :key="j" :lesson="lesson" :position="lesson.position"
          :theme="section.theme" :side="section.side" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import JourneyArc from "@/components/journey/JourneyArc.vue";
import JourneyDot from "@/components/journey/JourneyDot.vue";
import Badge from "@/components/badges/Badge.vue";
import JourneyLevelBanner from "@/components/journey/JourneyLevelBanner.vue";

const props = defineProps({
  section: Object,
  odd: Boolean
});

const lessons = computed(() => {
  return getLessons();
});

const badge = computed(() => {
  var result;

  result = props.section.theme.badge;
  if (result) {
    result.achieved = hasAnyWithStatus('present') && !(hasAnyWithStatus('locked'));
  }

  return result;
});

const allLocked = computed(() => {
  for (var lesson of props.section.lessons) {
    if (lesson.status !== 'locked') {
      return false;
    }
  }

  return true;
})

const anyLocked = computed(() => {
  return hasAnyWithStatus('locked');
})

const anyAttended = computed(() => {
  return hasAnyWithStatus('present');
})

function hasAnyWithStatus(status) {
  for (var lesson of props.section.lessons) {
    if (lesson.status === status) {
      return true;
    }
  }

  return false;
}

const title = computed(() => {
  return props.section.theme?.name;
});

const arcs = computed(() => {
  var result = [],
    lessons;

  lessons = getLessons();

  for (var i = 0; i < lessons.length; i++) {
    if (i % 2 === 0) {
      result.push(lessons[i]);
    }
  }

  return result;
});

function getLessons() {
  var result = [],
    positions = ['first', 'second', 'third', 'fourth'],
    side = props.section.side,
    lessonCount = 0;

  for (var lesson of props.section.lessons) {
    result.push({
      ...lesson,
      side: side,
      position: positions[lessonCount]
    })

    lessonCount++;
    if (lessonCount % 2 == 0) {
      side = side === 'left' ? 'right' : 'left';
    }
  }

  return result;
}

const themeBackground = computed(() => {
  var theme;

  theme = props.section.theme?.shortname || 'generic';
  return `url("/assets/theme-backgrounds/${theme}.svg")`;
});

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.journey-section {
  position: relative;
  align-items: center;

  &-inner {
    position: relative;
  }

  &-background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.1;
    z-index: 0;

    &-odd {
      opacity: 0.2;
      background-color: $lightIndigo;
    }
  }

  &-title {
    position: absolute;
    font-size: 1.3rem;
    color: $dark;
    display: flex;
    align-items: center;
    height: 10rem;
    color: $indigo;
    width: 10rem;
    justify-content: start;

    @media screen and (max-width: $minTablet) {
      font-size: 1.1rem;
    }

    p {
      margin: 0
    }
  }

  &.locked {
    :deep {

      .journey-arc,
      .journey-section-title {
        opacity: 0.3;
      }
    }
  }

  &-bootcamp {
    :deep {
      .journey-arc {
        path {
          fill: $teal;

          &.path-border {
            fill: $teal !important;
            ;
          }
        }
      }
    }
  }

  &-theme {
    &.even {
      :deep {
        .journey-arc {
          path {
            fill: $indigo;
          }
        }
      }
    }

    &.odd {
      :deep {
        .journey-arc {
          path {
            fill: $mediumIndigo;

            &.path-border {
              fill: $mediumIndigo !important;
              ;
            }
          }
        }
      }
    }
  }

  .journey-dots {
    position: absolute;
    top: 0;
    left: 0;
  }

  :deep {
    .badge {
      position: absolute;
    }
  }

  &-half {
    :deep {
      .journey-dot {
        margin-top: 4.3rem
      }
    }

    @media screen and (max-width: $minTablet) {
      .journey-section-title {
        display: none;
      }
    }

    &.journey-section-right {
      .journey-section-title {
        top: 0;
        left: 11rem;
        text-align: left;
        justify-content: start;
      }

      :deep {
        .badge {
          top: 1rem;
          right: 5rem;
          transform-origin: right;
        }
      }

      :deep {
        .journey-dot-first {
          margin-left: 7.4rem;
        }

        .journey-dot-second {
          margin-left: 7.4rem;
        }
      }
    }

    &.journey-section-left {
      .journey-section-title {
        top: 0;
        right: 11rem;
        text-align: right;
        justify-content: end;
      }

      :deep {
        .badge {
          top: 1rem;
          left: 5rem;
          transform-origin: left;
        }
      }

      :deep {
        .journey-dot-first {
          margin-left: 1.7rem;
        }

        .journey-dot-second {
          margin-left: 1.7rem;
        }
      }
    }
  }

  &-single {
    :deep {
      .journey-dot {
        margin-top: 2.3rem
      }
    }

    @media screen and (max-width: $minTablet) {
      .journey-section-title {
        display: none;
      }
    }

    &.journey-section-right {
      .journey-section-title {
        top: 0;
        left: 11rem;
        text-align: left;
        justify-content: start;
      }

      :deep {
        .badge {
          top: 1rem;
          right: 5rem;
          transform-origin: right;
        }
      }

      :deep {
        .journey-dot-first {
          margin-left: 7rem;
        }

        .journey-dot-second {
          margin-left: 7rem;
        }
      }
    }

    &.journey-section-left {
      .journey-section-title {
        top: 0;
        right: 11rem;
        text-align: right;
        justify-content: end;
      }

      :deep {
        .badge {
          top: 1rem;
          left: 5rem;
          transform-origin: left;
        }
      }

      :deep {
        .journey-dot-first {
          margin-left: 1.7rem;
        }

        .journey-dot-second {
          margin-left: 1.7rem;
        }
      }
    }
  }

  &-double {
    :deep {
      .journey-dot {
        margin-top: 3.2rem
      }

      .journey-dot-third {
        margin-top: 2.2rem
      }
    }

    &.journey-section-right {
      &.even {
        .journey-section-title {
          top: 0;
          right: 5rem;
          text-align: right;
          justify-content: end;
        }

        :deep {
          .badge {
            bottom: 1rem;
            left: 5rem;
            transform-origin: right;
          }
        }
      }

      &.odd {
        .journey-section-title {
          bottom: 0;
          left: 5rem;
          text-align: left;
        }

        :deep {
          .badge {
            top: 1rem;
            right: 5rem;
            transform-origin: left;
          }
        }
      }

      :deep {
        .journey-dot-first {
          margin-left: 7.2rem;
        }

        .journey-dot-second {
          margin-left: 5.9rem;
        }

        .journey-dot-third {
          margin-left: 2.1rem;
        }

        .journey-dot-fourth {
          margin-left: 1.7rem;
        }
      }
    }

    &.journey-section-left {
      &.even {
        .journey-section-title {
          top: 0;
          left: 5rem;
          text-align: left;
        }

        :deep {
          .badge {
            bottom: 1rem;
            right: 5rem;
            transform-origin: right;
          }
        }
      }

      &.odd {
        .journey-section-title {
          bottom: 0;
          right: 5rem;
          text-align: right;
          justify-content: end;
        }

        :deep {
          .badge {
            top: 1rem;
            left: 5rem;
            transform-origin: left;
          }
        }
      }

      :deep {
        .journey-dot-first {
          margin-left: 1.4rem;
        }

        .journey-dot-second {
          margin-left: 2.8rem;
        }

        .journey-dot-third {
          margin-left: 6.6rem;
        }

        .journey-dot-fourth {
          margin-left: 6.9rem;
        }
      }
    }
  }
}

:deep {
  .level-banner {
    position: absolute;
    top: -1.5rem;
    left: 2.5rem;
    width: 6rem;
    height: 4.58rem;
  }
}
</style>
