<template>
    <div ref="el" :class="challenge.toLowerCase()">{{ challenge[0] }}</div>
    <ToolTip :message="challenge" :anchor="el" />
</template>

<script setup>
import ToolTip from "@/components/ToolTip.vue";
import { ref } from 'vue';

const el = ref(null);

const props = defineProps({
    participants: Object,
    challenge: String
});
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';


div {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    color: white;
    font-style: normal;
    border: 1px solid $lightIndigo;

    &.attempt {
        background: $grey;
        background-image: $badgeAttemptGradient;
    }

    &.kilo {
        background: $gold;
        background-image: $badgeKiloGradient;
    }

    &.mega {
        background: $purple;
        background-image: $badgeMegaGradient;
    }

    &.giga {
        background: $teal;
        background-image: $badgeGigaGradient;
    }
}
</style>