<template>
    <div class="sound-chart">
        <span v-for="(val, i) in levels" :key="i" :class="soundLevel > val ? 'on' : ''"></span>
    </div>
</template>
  
<script setup>
import { ref, onMounted } from 'vue';

const props = defineProps(['soundLevel']);
const levels = ref([]);

onMounted(() => {
    for (var i = 1; i < 11; i++) {
        levels.value.push(1 - i * 0.1);
    }
    for (var i = 9; i >= 1; i--) {
        levels.value.push(1 - i * 0.1);
    }
});

</script>
  
<style lang="scss" scoped>
span {
    display: inline-block;
    height: 5vh;
    width: 10px;
    background: white;
    margin: 0 3px;
    opacity: 0.1;
    transition: all 0.3s;

    &.on {
        opacity: 1;
    }
}
</style>
  