<template>
    <DeviceCheck title="Camera Check" question="Can you see yourself?" :devices="devices" device-name="Camera"
        v-bind="$attrs" @deviceChange="onDeviceChange">
        <ParticipantVideo :participant="participant" />
    </DeviceCheck>
</template>
  
<script setup>
import ParticipantVideo from "@/components/classroom/video/ParticipantVideo.vue";
import DeviceCheck from "./DeviceCheck.vue";

const emit = defineEmits(['cameraChange']);
const props = defineProps(['devices', 'participant']);

function onDeviceChange(deviceId) {
    emit('cameraChange', deviceId);
}

</script>
  
<style lang="scss" scoped>
:deep {
    .participant-video {
        width: 100%;
    }
}
</style>
  