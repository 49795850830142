<template>
  <div ref="levelBannerEl"
    :class="['level-banner', level.platform ? `level-banner-${level.platform.toLowerCase()}` : '']">
    <div class="level-banner-inner">
      <p :class="level.achievedLevel > 9 ? 'double' : ''">{{ level.achievedLevel }}</p>
    </div>
  </div>
  <ToolTip :message="tooltipMessage" :anchor="levelBannerEl" />
</template>

<script setup>
import ToolTip from "@/components/ToolTip.vue";
import { ref, computed } from "vue";

const levelBannerEl = ref(null);

const props = defineProps({
  level: Object
});

const tooltipMessage = computed(() => {
  var result;

  result = `You reached level ${props.level.achievedLevel}`;

  if (props.level.platform) {
    result += ` in ${props.level.platform}`;
  }

  return result;
});

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.level-banner {
  width: 6rem;
  position: relative;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-image: url('~@/assets/journey/level-banner.png');
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;

  &-inner{
    padding-top: 76%;
  }

  &-scratch {
    background-image: url('~@/assets/journey/level-banner-scratch.png');
  }

  &-javascript {
    background-image: url('~@/assets/journey/level-banner-javascript.png');
  }

  &:hover {
    transform: scale(1.5);
    opacity: 1;
  }

  p {
    padding-bottom: 15%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    text-align: center;
    -webkit-text-stroke: 1px #b6004d;
    color: #c8631a;
    font-weight: bold;
    font-size: 1.8rem;

    &.double {
      font-size: 1.3rem;
    }
  }
}
</style>
