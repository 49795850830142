const sentry = require("@sentry/vue");

export default {
    log: function (message) {
        console.log(message);
    },

    message: function (message) {
        console.log(message);
        sentry.captureMessage(message);
    },

    error: function (error, message) {
        if (!(error instanceof Error)) {
            error = new Error(error);
        }

        message = (message ? message.substring(0, 150) + ' | ' : '') + error.message;

        try {
            error.message = message;
        }
        catch {
            // If message has no setter
        }

        sentry.captureException(error);
        console.log(error);
    }
}