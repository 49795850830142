<template>
  <div :class="['dual-frame', toggled ? 'toggled' : '']">
    <div class="dual-frame-pane dual-frame-pane-first">
      <div class="dual-frame-pane-inner">
        <slot name="first"></slot>
      </div>
    </div>
    <div class="dual-frame-pane dual-frame-pane-second">
      <div class="dual-frame-pane-inner">
        <slot name="second"></slot>
      </div>
    </div>
    <div class="dual-frame-toggle">
      <CircleButton ref="button" :fa-icon="`chevrons-${toggled ? 'right' : 'left'}`" @click="onToggleClick" />
    </div>
  </div>
</template>

<script setup>
import CircleButton from "@/components/buttons/CircleButton.vue";
import { ref } from "vue";

const toggled = ref(false);

function onToggleClick() {
  toggled.value = !toggled.value;
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.dual-frame {
  overflow: hidden;
  height: 100%;
  justify-content: center;
  align-items: stretch;

  &-pane {
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    width: 50%;
    top: 0;
    bottom: 0;

    &-first {
      left: 0;
    }

    &-inner {
      position: absolute;
      min-width: 300px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    @media screen and (max-width: $minTablet) {
      width: 80%;
      transition: all 0.3s;
    }

    &-second {
      border-left: 2px solid $indigo;
      background: white;
      right: 0;

      @media screen and (max-width: $minTablet) {
        width: 20%;
      }
    }
  }

  @media screen and (max-width: $minTablet) {
    &.toggled {
      .dual-frame {

        &-pane {
          width: 20%;

          &-second {
            width: 80%;
          }
        }

        &-toggle {
          left: calc(20% - 2vh);
        }
      }
    }
  }

  &-toggle {
    display: none;

    @media screen and (max-width: $minTablet) {
      display: block;
      position: absolute;
      left: calc(70% + 2vh);
      bottom: 10vh;
      z-index: 1000;
      transition: all 0.3s;

      :deep {
        .icon-button-icon {
          background-color: white;
          border: 2px solid $indigo;
        }

      }
    }
  }
}
</style>
