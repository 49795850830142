L<template>
  <div :class="['journey light h-100 d-flex']">
    <div class="journey-main d-flex h-100 flex-column">
      <div class="journey-body">
        <template v-if="journeyPackage && journeyPackage.sections">
          <DualFrameLayout>
            <template #first>
              <LearningJourneyFrame :sections="journeyPackage.sections" />
            </template>

            <template #second>
              <BadgesFrame v-if="badgesPackage" :badges="badgesPackage" :level="journeyPackage?.currentLevel" />
              <WaitingAnimation v-else></WaitingAnimation>
            </template>
          </DualFrameLayout>
        </template>
        <JourneyWaiting v-else />
      </div>
    </div>
  </div>
</template>

<script setup>

import LearningJourneyFrame from '@/components/journey/LearningJourneyFrame.vue';
import BadgesFrame from '@/components/badges/BadgesFrame.vue';
import DualFrameLayout from '@/components/DualFrameLayout.vue';
import apiHelpers from '@/scripts/helpers/apiHelpers.js';
import JourneyWaiting from '@/components/journey/JourneyWaiting';
import WaitingAnimation from '@/components/layout/WaitingAnimation';
import { useRoute } from 'vue-router';
import _ from 'lodash';
import { ref, computed, onMounted } from "vue";

const studentUuid = ref(),
  journeyPackage = ref(),
  badgesPackage = ref(),
  route = useRoute();

onMounted(async () => {
  studentUuid.value = route.params.studentUuid;
  journeyPackage.value = await fetchJourneyPackage();
  badgesPackage.value = await fetchBadgesPackage();
});

async function fetchJourneyPackage() {
  var endpoint;

  endpoint = `journey/${studentUuid.value}`;

  return await apiHelpers.get(endpoint);
}

async function fetchBadgesPackage() {
  var endpoint;

  endpoint = `badges/${studentUuid.value}`;

  return await apiHelpers.get(endpoint, true);
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.journey {
  overflow: hidden;
  background-size: cover;

  &-main {
    flex: 1;
  }

  &-body {
    justify-content:flex-start; 
    overflow: auto;
    flex-grow: 1;
    margin: 0;
    flex-wrap:wrap;
    align-items: center;;
  }
}

.logo {
  padding: 2vh 0 1vh 0;
  text-align: center;
  margin: 0 auto;

  >img {
    width: 15vh;
  }
}
</style>
