<template>
    <DeviceCheck title="Speaker Check" question="Can you hear the sound?" :devices="devices" device-name="Speaker"
        v-bind="$attrs" @deviceChange="onDeviceChange">
        <div class="speaker-icon"><i class="fa fa-volume-high"></i></div>
    </DeviceCheck>
</template>
  
<script setup>
import { onMounted, onUnmounted } from 'vue';
import DeviceCheck from "./DeviceCheck.vue";
import robotSound from "@/assets/sounds/robotSound.wav";

var soundTimeout;
const emit = defineEmits(['speakerChange']);
const props = defineProps(['devices']);
const audio = new Audio(robotSound);

onMounted(() => {
    audio.addEventListener('ended', replaySound);
    setAudioSpeaker();
    playSound();
});

onUnmounted(() => {
    endSound();
});

function getActiveDevice() {
    return _.find(props.devices, (d) => { return d.active ? true : false; });
}

function setAudioSpeaker(deviceId = null) {
    if (!deviceId) {
        deviceId = getActiveDevice()?.deviceId;
    }

    if (deviceId && audio?.setSinkId) {
        audio.setSinkId(deviceId);
    }
}

function playSound() {
    audio.play();
}

function replaySound() {
    soundTimeout = setTimeout(() => {
        playSound();
    }, 2000);
}

function endSound() {
    if (soundTimeout) {
        clearTimeout(soundTimeout);
    }

    audio.pause();
    audio.removeEventListener('ended', replaySound);
}

function onDeviceChange(deviceId) {
    setAudioSpeaker(deviceId);
    emit('speakerChange', deviceId);
}

</script>
  
<style lang="scss" scoped>
.speaker-icon {
    font-size: 10vh;
    opacity: 0.5;
}
</style>
  