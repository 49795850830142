<template>
    <div class="settings-tile d-flex">
        <div class="settings-item">
            <IconButton ref="cameraButton" fa-icon="camera" size="medium" label="Camera" :shadow="false"
                :disabled="!hasCameraDevices" @click="onCameraButtonClick" />
            <DevicePopupMenu ref="cameraMenu" :devices="cameraDevices" :anchor="cameraButton?.el"
                @deviceClick="onCameraMenuClick" />
        </div>
        <div class="settings-item">
            <IconButton ref="microphoneButton" fa-icon="microphone" size="medium" label="Microphone" :shadow="false"
                :disabled="!hasMicrophoneDevices" @click="onMicrophoneButtonClick" />
            <DevicePopupMenu ref="microphoneMenu" :devices="microphoneDevices" :anchor="microphoneButton?.el"
                @deviceClick="onMicrophoneMenuClick" />
        </div>
        <div class="settings-item">
            <IconButton ref="speakerButton" fa-icon="volume-high" size="medium" label="Speaker" :shadow="false"
                :disabled="!hasSpeakerDevices" @click="onSpeakerButtonClick" />
            <DevicePopupMenu ref="speakerMenu" :devices="speakerDevices" :anchor="speakerButton?.el"
                @deviceClick="onSpeakerMenuClick" />
        </div>
        <template v-if="admin">
            <div class="settings-item">
                <IconButton ref="classroomButton" fa-icon="chalkboard-user" size="medium" label="Classroom" :shadow="false"
                    @click="onClassroomButtonClick" />
                <PopupMenu ref="classroomMenu" :anchor="classroomButton?.el">
                    <PopupMenuItem :label="classroomSettings.enableChat ? 'Disable chat' : 'Enable chat'"
                        @click="onChatClick" />
                </PopupMenu>
            </div>
        </template>
        <ModalMessage ref="modalMessage" />
    </div>
</template>
  
<script setup>
import DevicePopupMenu from "@/components/menus/DevicePopupMenu.vue";
import PopupMenu from "@/components/menus/PopupMenu.vue";
import PopupMenuItem from "@/components/menus/PopupMenuItem.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import { computed, ref } from "vue";
import ModalMessage from "@/components/ModalMessage.vue";

const cameraButton = ref(null),
    microphoneButton = ref(null),
    speakerButton = ref(null),
    classroomButton = ref(null),
    cameraMenu = ref(null),
    microphoneMenu = ref(null),
    speakerMenu = ref(null),
    classroomMenu = ref(null),
    modalMessage = ref(null);

const props = defineProps({
    devices: Object,
    admin: Boolean,
    classroomSettings: Object
});

const emit = defineEmits(['deviceClick', 'cameraChange', 'microphoneChange', 'speakerChange', 'toggleChat']);

const cameraDevices = computed(() => {
    return getDevices('camera');
});

const microphoneDevices = computed(() => {
    return getDevices('microphone');
});

const speakerDevices = computed(() => {
    return getDevices('speaker');
});

const hasCameraDevices = computed(() => {
    return getHasDevices('camera');
});

const hasMicrophoneDevices = computed(() => {
    return getHasDevices('microphone');
});

const hasSpeakerDevices = computed(() => {
    return getHasDevices('speaker');
});

function getHasDevices(type) {
    var foundDevices;

    foundDevices = getDevices(type);
    return foundDevices && foundDevices.length > 0;
}

function getDevices(type) {
    // Clone to ensure reactivity
    return _.clone(props.devices[type]);
}

function onCameraButtonClick() {
    onDeviceButtonClick('camera', cameraMenu.value);
}

function onMicrophoneButtonClick() {
    onDeviceButtonClick('microphone', microphoneMenu.value);
}

function onSpeakerButtonClick() {
    onDeviceButtonClick('speaker', speakerMenu.value);
}

function onDeviceButtonClick(type, menu) {
    if (getHasDevices(type)) {
        menu.toggle();
    }
    else {
        modalMessage.value.show({
            title: `No ${type}s found`,
            message: `Your browser hasn't given us permission to display a list of ${type}s for your device.<br><br>Please refer to your browser's settings.`,
            buttons: [{ label: 'OK' }]
        });
    }
}

function onClassroomButtonClick() {
    classroomMenu.value.toggle();
}

function onCameraMenuClick(value) {
    emit('cameraChange', value);
}

function onMicrophoneMenuClick(value) {
    emit('microphoneChange', value);
}

function onSpeakerMenuClick(value) {
    emit('speakerChange', value);
}

function onChatClick() {
    emit('toggleChat');
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.settings-tile {
    padding: 2vh;
    gap: 4vh;
    flex-wrap: wrap;
    justify-content: center;

    .settings-item {
        .icon-button {
            color: $indigo;
        }
    }
}
</style>
  