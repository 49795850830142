<template>
    <ClassroomLayout>
        <div class="classroom-early">
            <h3>Your next lesson starts in</h3>
        </div>
        <CountdownTimer v-if="countingDown" :time="timeToStart" :target-time="joinTime"
            @targetTimeReached="onJoinTimeReached" @ended="onCountdownEnded"></CountdownTimer>
        <div :class="['classroom-early-button', canJoin ? 'on' : '']">
            <div class="button button-big" @click="joinClick">Join</div>
        </div>
    </ClassroomLayout>
</template>

<script setup>

import ClassroomLayout from "@/components/classroom/ClassroomLayout.vue";
import CountdownTimer from "@/components/CountdownTimer.vue";
import { onMounted, ref } from "vue";

const props = defineProps({
    timeToStart: Number,
    joinTime: Number
});
const emit = defineEmits(['joinCall']);

const countingDown = ref(true),
    canJoin = ref(false);

onMounted(() => {
    countingDown.value = props.timeToStart ? true : false;
})

function joinClick() {
    emit('joinCall');
}

function onJoinTimeReached() {
    canJoin.value = true;
}

function onCountdownEnded() {
    emit('joinCall');
}
</script>

<style lang="scss" scoped>
.classroom-early {
    text-align: center;

    &-button {
        visibility: hidden;
        display: flex;
        justify-content: center;
        margin-top: 2rem;

        &.on {
            visibility: visible;
        }
    }

    h3 {
        margin-bottom: 50px
    }
}
</style>
