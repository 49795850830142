<template>
  <div ref="levelChartEl" :class="['level-chart']" :style="{ backgroundImage: chartBackground }">
    <div class="level-chart-inner"></div>
  </div>
  <ToolTip :message="tooltipMessage" :anchor="levelChartEl" />
</template>

<script setup>
import ToolTip from "@/components/ToolTip.vue";
import { ref, computed } from "vue";

const levelChartEl = ref(null);

const props = defineProps({
  level: Object
});

const tooltipMessage = computed(() => {
  var result;

  result = `You're on level ${props.level.achievedLevel} out of 15`;

  if (props.level.platform) {
    result += ` for ${props.level.platform}`;
  }

  return result;
});

const chartBackground = computed(() => {
  return `url("/assets/level-charts/${props.level.platform.toLowerCase()}${props.level.achievedLevel}.png")`;
});

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.level-chart {
  width: 100%;
  max-width: 600px;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  &-inner {
    padding-top: 9.5%;
  }
}
</style>
