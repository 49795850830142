<template>
  <div class="waiting-video-tile">
    <p><span>{{ displayName }}</span>{{ size === 'small' ? '' : ' is waiting to join' }}</p>

    <div class="waiting-video-tile-buttons">
      <CircleButton fa-icon="check" :size="size === 'small' ? 'small' : 'medium'" icon-class="waiting-button"
        :label="size !== 'small' ? 'Accept' : ''" :shadow="false" @click="onAdmitClick" />
      <CircleButton fa-icon="xmark" :size="size === 'small' ? 'small' : 'medium'" icon-class="waiting-button"
        :label="size !== 'small' ? 'Deny' : ''" :shadow="false" @click="onDenyClick" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import CircleButton from "@/components/buttons/CircleButton.vue";

const props = defineProps({
  participant: Object,
  size: String
});

const emit = defineEmits(['admitClick', 'denyClick']);


const displayName = computed(() => {
  return props.participant.user_name + (props.participant.local ? ' (You)' : '');
});

function onAdmitClick() {
  emit('admitClick', props.participant);
}

function onDenyClick() {
  emit('denyClick', props.participant);
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.waiting-video-tile {
  background-color: $lightIndigo;
  height: 100%;
  max-height: 100%;
  aspect-ratio: 16/9;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    margin: 0 0 2vh 0;
    text-align: center;

    span {
      display: block;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 1vh;
    }
  }

  &-buttons {
    display: flex;
    justify-content: center;
    gap: 3vh;
  }
}

:deep {
  .waiting-button {
    border: 1px solid #fff;
  }
}
</style>