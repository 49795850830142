<template>
  <div class="call-control">
    <CircleButton ref="button" :icon="icon" :fa-icon="faIcon" :label="label" size="large" :icon-class="iconClass"
      @click="onButtonClick" />
    <template v-if="devices">
      <CircleButton ref="ellipsis" class="ellipsis" fa-icon="ellipsis" size="small" icon-class="ellipsis-icon"
        @click="onEllipsisClick" />
      <DevicePopupMenu ref="menu" :anchor="ellipsis?.el" :devices="devices" @deviceClick="onDeviceClick" />
    </template>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import CircleButton from "@/components/buttons/CircleButton.vue";
import DevicePopupMenu from "@/components/menus/DevicePopupMenu.vue";

const menu = ref(null),
  ellipsis = ref(null),
  button = ref(null);

const props = defineProps({
  icon: String,
  faIcon: String,
  label: String,
  devices: Object,
  menuOpen: Boolean,
  iconClass: String
});

const el = computed(() => {
  return button.value?.el;
})

defineExpose({ el });

const emit = defineEmits(['click', 'deviceClick']);

function onButtonClick(e) {
  emit('click', e);
}

function onEllipsisClick() {
  menu.value.toggle();
}

function onDeviceClick(value) {
  emit('deviceClick', value);
}

</script>

<style lang="scss" scoped>
.call-control {
  position: relative;
  display: inline-block;
  text-align: center;
}

.ellipsis {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 10px auto 0 auto;

  &:hover {
    opacity: 1;
  }
}

:deep {
  .ellipsis-icon {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.popup {
  top: auto;
  bottom: 0;
}
</style>
