<template>
  <div :class="['video-tile', !videoStream ? 'empty' : '']">
    <audio ref="audio" autoPlay playsInline :srcObject="audioStream">
      <track kind="captions" />
    </audio>

    <template v-if="videoStream">
      <div v-if="isLoading" class="video-waiting">
        <WaitingAnimation></WaitingAnimation>
      </div>
      <video v-else ref="video" autoPlay muted playsInline :class="reverse ? 'reverse' : ''" @resize="onResize"></video>
    </template>

    <div v-else class="no-video stretch"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUpdated, computed } from 'vue';
import WaitingAnimation from '@/components/layout/WaitingAnimation';

const videoStream = ref(null),
  audioStream = ref(null),
  audio = ref(null),
  video = ref(null);

const props = defineProps({
  videoTrack: Object,
  audioTrack: Object,
  reverse: Boolean,
  width: Number
});

const emit = defineEmits(['resize']);
defineExpose({ getRatio });

onMounted(() => updateMedia());
onUpdated(() => updateMedia());

const isLoading = computed(() => {
  return !['playable', 'interrupted'].includes(props.videoTrack?.state);
})

function updateMedia() {
  videoStream.value = updateSource(videoStream.value, props.videoTrack?.persistentTrack);
  audioStream.value = updateSource(audioStream.value, props.audioTrack?.persistentTrack);

  if (video.value) {
    video.value.srcObject = videoStream.value;
  }

  if (audio.value) {
    audio.value.srcObject = audioStream.value;
  }
}

// Updates the given stream with new tracks OR
// returns an entirely new stream
function updateSource(stream, newTrack) {
  var existingTracks,
    existingTrack;

  if (!newTrack) {
    return;
  }

  existingTracks = stream?.getTracks();

  // If the stream parameter contains no existing tracks,
  // just return a new MediaStream to set. This should
  // only happen the first time the tile is initialized.
  if (!existingTracks || existingTracks.length === 0) {
    return new MediaStream([newTrack]);
  }
  if (existingTracks.length > 1) {
    console.warn(
      `expected 1 track, found ${existingTracks.length}. Only using the first one.`
    );
  }

  existingTrack = existingTracks[0];

  // If existing track is different from the new track,
  // remove the existing track and add the new one.
  if (newTrack.id !== existingTrack.id) {
    stream.removeTrack(existingTrack);
    stream.addTrack(newTrack);
  }

  return stream;
}

function getRatio() {
  var settings = videoStream.value.getTracks()[0].getSettings();
  return settings.width / settings.height;
}

function onResize() {
  emit('resize');
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.video-tile {
  background: $darker;

  &.empty {
    padding-top: 56%;
  }

  &-name {
    text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.47);
    position: absolute;
    color: #fff;
    bottom: 5px;
    left: 12px;
    margin: 0;
    font-size: 80%;
  }

  &-buttons {
    position: absolute;
    display: flex;
    right: 10px;
    top: 10px;
    gap: 10px;

    img {
      border: 1px solid #fff;
      border-radius: 50%;
      padding: 5px
    }
  }

  video {
    display: block;
    background: $darker;
    width: 100%;

    &.reverse {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }

  .emoji {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    z-index: 10;

    .animated-emoji {
      width: 33%;
      max-width: 10vh;
    }
  }

  .no-video {
    background-color: $darker;
    background-image: url('~@/assets/Coco_translucent.png');
    background-position: center center;
    background-size: 30%;
    background-repeat: no-repeat;
    height: 100%;
    max-height: 100%;
  }
}

.video-waiting {
  width: 1280px;
  height: 720px;
}
</style>
