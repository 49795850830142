<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190 185" class="journey-arc">
    <g id="Layer_1-2" data-name="Layer 1">
      <path :d="pathBorderString" class="path-border" />
      <path :d="pathOuterString" class="path-outer" />
      <path :d="pathString" />
    </g>
  </svg>
</template>

<script setup>
import { ref, computed } from "vue";

const width = ref(90);

const props = defineProps({
  side: String
});

const pathString = computed(() => {
  return getPathString();
});

const pathOuterString = computed(() => {
  return getPathString(3);
});

const pathBorderString = computed(() => {
  return getPathString(6);
});

function getPathString(offset = 0) {
  var pathWidth = width.value + (offset * 2),
    startPoint = 52 - offset,
    curveAdj = offset / 3;

  if (props.side === 'right') {
    return `M${startPoint},-0.01
          h${pathWidth - 1}
          c${59 - curveAdj},${40 + curveAdj},${59 - curveAdj},${142 - curveAdj},-0.5,186
          h-${pathWidth + 1}
          c${93 + curveAdj},${-61 + curveAdj},${93 + curveAdj},${-122 - curveAdj},1.5,-186`;
  }
  else {
    return `M${startPoint},-0.01
            h${pathWidth}
            c${-92 - curveAdj},${61 + curveAdj},${-92 - curveAdj},${123 - curveAdj},-1,185
            h-${pathWidth}
            c${-60 + curveAdj},${-40 + curveAdj},${-60 + curveAdj},${-143 - curveAdj},0,-185`;
  }
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.journey-arc {
  height: 10rem;
  display: block;

  path {
    fill: #553795;
    stroke-width: 0px;

    &.path-outer {
      fill: white !important;
      ;
    }

    &.path-border {
      fill: $indigo !important;
      ;
    }
  }
}
</style>
