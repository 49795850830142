import * as VueRouter from 'vue-router';
import ClassroomPage from '@/pages/ClassroomPage.vue';
import JourneyPage from '@/pages/JourneyPage.vue'; 

const routes = [
  {
    name: 'Home', path: '/',
    beforeEnter() { window.location.replace("https://www.cococoders.com") }
  },
  { name: 'Journey', path: '/journey/:studentUuid/:studentName?', component: JourneyPage },
  { name: 'DeviceCheck', path: '/check', component: ClassroomPage },
  { name: 'StudentJoin', path: '/join/:studentUuid/:studentName?', component: ClassroomPage },
  { name: 'ClassroomTestTeacher', path: '/classroom/test/t/:teacherUuid?', component: ClassroomPage },
  { name: 'ClassroomTestStudent', path: '/classroom/test/:teacherUuid?', component: ClassroomPage },
  { name: 'ClassroomTeacher', path: '/classroom/t/:lessonUuid/:teacherUuid?', component: ClassroomPage },
  { name: 'ClassroomStudent', path: '/classroom/:lessonUuid/:studentUuid?', component: ClassroomPage },
];

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes
});

export default router;