export default [
    { key: 'smile', faIcon: 'face-smile', notoEmojiId: '1f600' },
    { key: 'laugh', faIcon: 'face-grin-tears', notoEmojiId: '1f602' },
    { key: 'sad', faIcon: 'face-frown', notoEmojiId: '2639_fe0f' },
    { key: 'clap', faIcon: 'hands-clapping', notoEmojiId: '1f44f' },
    { key: 'thumbsUp', faIcon: 'thumbs-up', notoEmojiId: '1f44d' },
    { key: 'heart', faIcon: 'heart', notoEmojiId: '2764_fe0f' },

    // Awesome Aviation
    // { key: 'plane-departure', faIcon: 'plane-departure', notoEmojiId: '1f6eb' },
    // { key: 'rocket', faIcon: 'rocket', notoEmojiId: '1f680' },
    // { key: 'flying-saucer', faIcon: 'ufo', notoEmojiId: '1f6f8' },

    // Code Adventure
    // { key: 'gem', faIcon: 'gem', notoEmojiId: '1f48e' },
    // { key: 'unicorn', faIcon: 'unicorn', notoEmojiId: '1f984' },
    // { key: 'dragon', faIcon: 'dragon', notoEmojiId: '1f409' },

    // Winter Wonderland
    // { key: 'snowflake', faIcon: 'snowflake', notoEmojiId: '2744_fe0f' },
    // { key: 'cold', faIcon: 'face-icicles', notoEmojiId: '1f976' },

    // Robotics
    // { key: 'robot', faIcon: 'robot', notoEmojiId: '1f916' },
    // { key: 'arm-mechanical', faIcon: 'hand-fist', notoEmojiId: '1f9be' },

    // Inventor's Workshop
    // { key: 'electricity', faIcon: 'bolt', notoEmojiId: '26a1' },
    // { key: 'light-bulb', faIcon: 'lightbulb', notoEmojiId: '1f4a1' },
    // { key: 'gear', faIcon: 'gear', notoEmojiId: '2699_fe0f' },

    // { key: 'rainbow', faIcon: 'rainbow', notoEmojiId: '1f308' },
    // { key: 'pencil', faIcon: 'pencil', notoEmojiId: '270f_fe0f' },

    // { key: 'collision', faIcon: 'pencil', notoEmojiId: '1f4a5' },

    //Science Fiction
    // { key: 'alien', faIcon: 'alien', notoEmojiId: '1f47d' },
    // { key: 'flying-saucer', faIcon: 'ufo', notoEmojiId: '1f6f8' },
    // { key: 'rocket', faIcon: 'rocket', notoEmojiId: '1f680' },

    // Sports
    // { key: 'tennis-racquet', faIcon: 'racquet', notoEmojiId: '1f3be' },
    // { key: 'soccer-ball', faIcon: 'futbol', notoEmojiId: '26bd' },
    // { key: 'bowling', faIcon: 'bowling-ball-pin', notoEmojiId: '1f3b3' },

    // Under the Sea
    // { key: 'blowfish', faIcon: 'fish', notoEmojiId: '1f421' },
    // { key: 'octopus', faIcon: 'octopus', notoEmojiId: '1f419' },
    // { key: 'ocean', faIcon: 'wave', notoEmojiId: '1f30a' },

    // Puzzles
    // { key: 'thinking', faIcon: 'face-thinking', notoEmojiId: '1f914' },
    // { key: 'light-bulb', faIcon: 'lightbulb', notoEmojiId: '1f4a1' },
    // { key: 'monocle', faIcon: 'face-monocle', notoEmojiId: '1f9d0' },

    // // World Travel
    // { key: 'world', faIcon: 'earth-americas', notoEmojiId: '1f30e' },
    // { key: 'plane-departure', faIcon: 'plane-departure', notoEmojiId: '1f6eb' },

    // Obstacle Courses
    { key: 'construction', faIcon: 'road-barrier', notoEmojiId: '1f6a7' },
    { key: 'chequered-flag', faIcon: 'flag-checkered', notoEmojiId: '1f3c1' },

];