<template>
  <div class="side-panel-divider">
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.side-panel-divider {
  height: 2px;
  background-color: $lightIndigo;
}
</style>
