<template>
  <div :class="['countdown', output ? 'on' : '']">
    <div v-if="showDays" class="countdown-part">
      <i>{{ output?.days }}</i>
      <p>days</p>
    </div>
    <div class="countdown-part">
      <i>{{ output?.hours }}</i>
      <p>hours</p>
    </div>
    <div class="countdown-part">
      <i>{{ output?.minutes }}</i>
      <p>mins</p>
    </div>
    <div class="countdown-part">
      <i>{{ output?.seconds }}</i>
      <p>secs</p>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";

const emit = defineEmits(['ended', 'targetTimeReached']);

const props = defineProps({
  time: Number,
  targetTime: Number
});

const showDays = computed(() => {
  return output.value?.days ? true : false;
});

const secondsRemaining = ref(null);

const output = computed(() => {
  var result;

  if (secondsRemaining.value !== null) {
    const timeDifference = secondsRemaining.value * 1000;
    const millisecondsInOneSecond = 1000;
    const millisecondsInOneMinute = millisecondsInOneSecond * 60;
    const millisecondsInOneHour = millisecondsInOneMinute * 60;
    const millisecondsInOneDay = millisecondsInOneHour * 24;
    const differenceInDays = timeDifference / millisecondsInOneDay;
    const remainderDifferenceInHours = (timeDifference % millisecondsInOneDay) / millisecondsInOneHour;
    const remainderDifferenceInMinutes = (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute;
    const remainderDifferenceInSeconds = (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond;
    const remainingDays = Math.floor(differenceInDays);
    const remainingHours = Math.floor(remainderDifferenceInHours);
    const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
    const remainingSeconds = Math.floor(remainderDifferenceInSeconds);
    result = {
      days: remainingDays,
      hours: remainingHours,
      minutes: remainingMinutes,
      seconds: remainingSeconds
    }
  }

  if (secondsRemaining.value === props.targetTime) {
    emit('targetTimeReached');
  }

  return result;
});

function start() {
  secondsRemaining.value = secondsRemaining.value !== null ? secondsRemaining.value : props.time;
  secondsRemaining.value = secondsRemaining.value - 1;

  if (secondsRemaining.value > 0) {
    setTimeout(() => {
      start();
    }, 1000);
  }
  else {
    emit('ended');
  }
}

onMounted(() => {
  start();
});
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.countdown {
  visibility: hidden;
  display: flex;
  justify-content: center;

  &.on {
    visibility: visible;
  }

  &-part {
    display: inline-block;
    text-align: center;
    margin: 0 3px;

    i {
      background-color: rgba(255, 255, 255, 0.3);
      color: white;
      width: 75px;
      height: 75px;
      border-radius: 5px;
      display: block;
      font-style: normal;
      line-height: 75px;
      font-size: 150%;
    }

    p {
      margin-top: 3px;
    }
  }
}
</style>
