<template>
  <div class="participant-video">
    <VideoTile :audio-track="audioTrack" :video-track="videoTrack" :reverse="participant.local" />
    <p v-if="showName" class="participant-video-name">{{ displayName }}</p>
    <div class="participant-video-buttons">
      <template v-if="admin">
        <CircleButton v-if="hasScreenShare" ref="screenShareButton"
          :alt="(isPinnedScreenShare ? `This participant's screen share is visible to everyone. ` : '') + `Click to view their screen share`"
          :icon-class="isPinnedScreenShare ? 'screen-share-pinned' : isActiveScreenShare ? 'screen-share-active' : 'participant-button'"
          fa-icon="display" :size="size === 'small' ? 'small' : 'medium'" @click="onScreenShareClick" />
        <CircleButton :fa-icon="participant.audio ? 'microphone' : 'microphone-slash'"
          :size="size === 'small' ? 'small' : 'medium'" icon-class="participant-button" @click="onMuteClick" />
        <CircleButton ref="ellipsisButton" fa-icon="ellipsis" :size="size === 'small' ? 'small' : 'medium'"
          icon-class="participant-button" @click="onEllipsisClick" />
        <PopupMenu ref="menu" :anchor="ellipsisButton?.el">
          <template v-if="!participant.local">
            <PopupMenuItem v-if="participant.video" label="Stop camera" @click="onHideClick" />
            <PopupMenuItem v-if="participant.screenVideoTrack" label="Stop screen share"
              @click="onStopScreenShareClick" />
            <PopupMenuItem v-if="participant.video" label="Toggle blur" @click="onToggleBackgroundClick" />
            <PopupMenuItem label="Eject" @click="onEjectClick" />
          </template>
          <template v-else>
            <PopupMenuItem label="Toggle background" @click="onToggleBackgroundClick" />
          </template>
        </PopupMenu>
      </template>

      <template v-else>
        <CircleButton v-if="!participant.audio" class="participant-icon" fa-icon="microphone-slash"
          :disabled="!participant.local" :size="size === 'small' ? 'small' : 'medium'"
          v-on="participant.local ? { click: onMuteClick } : {}" />
      </template>
    </div>

    <StatusDot v-if="(admin || participant.local) && participant.userData?.status" :participant="participant"
      :status="participant.userData?.status" :online="participant.userData?.online" />
    <div v-if="handIsUp"
      :class="['hand', (admin || participant.local) ? 'clickable' : '', size === 'small' ? 'small' : '']"
      @click="onHandClick" v-html="'&#9995;'"></div>

    <Transition>
      <div v-if="notoEmojiId" class="emoji">
        <AnimatedEmoji :noto-emoji-id="notoEmojiId" />
      </div>
      <div v-else-if="emojiCode" class="emoji">
        <div class="static-emoji" v-html="emojiCode"></div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import CircleButton from "@/components/buttons/CircleButton.vue";
import VideoTile from "@/components/classroom/video/VideoTile.vue";
import AnimatedEmoji from "@/components/AnimatedEmoji.vue";
import PopupMenu from "@/components/menus/PopupMenu.vue";
import PopupMenuItem from "@/components/menus/PopupMenuItem.vue";
import StatusDot from "@/components/classroom/StatusDot.vue";
import emojis from "@/data/emojis";
import _ from 'lodash';

const menu = ref(null),
  ellipsisButton = ref(null);

const props = defineProps({
  participant: Object,
  admin: Boolean,
  size: String,
  isPinnedScreenShare: Boolean,
  isActiveScreenShare: Boolean,
  showName: Boolean
});

const emit = defineEmits(['mute', 'hide', 'screenShareClick', 'stopScreenShare', 'dismissHand', 'eject', 'toggleBackground']);

const audioTrack = computed(() => {
  return !props.participant.local && props.participant.audio ? props.participant.tracks?.audio : null;
});

const videoTrack = computed(() => {
  return props.participant.video ? props.participant.tracks?.video : null;
});

const notoEmojiId = computed(() => {
  return getNotoEmojiId(props.participant?.userData?.emoji);
});

const emojiCode = computed(() => {
  return getEmojiCode(props.participant?.userData?.emoji);
});

const handIsUp = computed(() => {
  return props.participant?.userData?.hand;
});

const displayName = computed(() => {
  return props.participant.user_name + (props.participant.local ? ' (You)' : '');
});

const hasScreenShare = computed(() => {
  return props.participant.screenVideoTrack;
});

function getNotoEmojiId(key) {
  return key ? _.find(emojis, (e) => { return e.key === key })?.notoEmojiId : null;
}

function getEmojiCode(key) {
  var emoji;

  if (key) {
    emoji = _.find(emojis, (e) => { return e.key === key });
    if (emoji) {
      return emoji.emojiCode;
    }
  }
}

function onEllipsisClick() {
  menu.value.toggle();
}

function onMuteClick() {
  emit('mute', props.participant);
}

function onHideClick() {
  emit('hide', props.participant);
}

function onEjectClick() {
  emit('eject', props.participant);
}

function onStopScreenShareClick() {
  emit('stopScreenShare', props.participant);
}

function onScreenShareClick() {
  emit('screenShareClick', props.participant);
}

function onHandClick() {
  if (props.participant.local || props.admin) {
    emit('dismissHand', props.participant);
  }
}

function onToggleBackgroundClick() {
  emit('toggleBackground', props.participant);
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.participant-video {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 16/9;
  max-height: 100%;
  margin: 0 auto;

  &-name {
    text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.47);
    position: absolute;
    color: #fff;
    bottom: 5px;
    left: 12px;
    margin: 0;
    font-size: 80%;
  }

  &-buttons {
    position: absolute;
    display: flex;
    right: 10px;
    top: 10px;
    gap: 10px;
    z-index: 20;

    img {
      border: 1px solid #fff;
      border-radius: 50%;
      padding: 5px
    }
  }

  :deep {
    .status-dot {
      position: absolute;
      left: 10px;
      top: 10px;
    }

    .screen-share-pinned {
      border: 1px solid #fff;
      background-color: $blue;
    }

    .screen-share-active {
      border: 1px solid #fff;
      background-color: white;
      color: $darker;
    }

    .participant-button {
      border: 1px solid #fff;
      background-color: rgba(0, 0, 0, 0.7);
    }

    .participant-icon {
      div {
        background: rgba(0, 0, 0, 0.7);
      }
    }
  }

  .hand {
    position: absolute;
    left: 2vh;
    top: 2vh;
    font-size: 5vh;
    z-index: 20;

    &.clickable {
      cursor: pointer
    }

    &.small {
      font-size: 4vh;
      left: 1vh;
      top: 1vh;
    }
  }

  .emoji {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    z-index: 10;
    align-items: center;

    .animated-emoji {
      width: 33%;
      max-width: 10vh;
    }

    .static-emoji {
      font-size: 10vh;
    }
  }
}
</style>
