<template>
  <div ref="el" :class="['icon-button', size ? size : '', label ? 'with-label' : '', disabled ? 'disabled' : '']"
    @click="onClick">
    <div :title="alt || label" :class="['icon-button-icon', iconClass]"
      :style="{ backgroundImage: icon ? `url(${icon})` : null }">
      <i v-if="faIcon" :class="['fa', 'fa-' + faIcon]"></i>
    </div>
    <p v-if="label">{{ label }}</p>
    <ToolTip v-if="tooltip" :message="tooltip" :anchor="el" />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import ToolTip from "@/components/ToolTip.vue";

const el = ref(null);

const props = defineProps({
  icon: String,
  faIcon: String,
  label: String,
  size: String,
  iconClass: String,
  alt: String,
  disabled: Boolean,
  tooltip: String
});

const emit = defineEmits(['click']);

defineExpose({ el });

function onClick(e) {
  emit('click', e);
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.dark {
  .icon-button {
    color: white;
  }
}

.icon-button {
  text-align: center;
  display: inline-block;
  position: relative;
  width: 5vh;
  color: black;

  @media screen and (min-width: $minTablet) {
    width: 5vh;
  }

  &.with-label {
    padding-bottom: 3vh;
  }

  div {
    width: 5vh;
    height: 3vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: inherit;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }

    i {
      font-size: 2vh;
    }

    @media screen and (min-width: $minTablet) {
      width: 5vh;
      height: 3vh;
    }
  }

  &.disabled {
    >div {
      opacity: 0.5;

      &:hover {
        transform: none;
      }
    }

    >p {
      opacity: 0.5;
    }
  }

  &.large {
    width: 7vh;

    div {
      width: 7vh;
      height: 4vh;
    }

    i {
      font-size: 3vh;
      line-height: 4vh;
    }
  }

  &.small {
    width: 3.5vh;

    div {
      width: 3.5vh;
      height: 2.5vh;
    }

    i {
      font-size: 1.5vh;
    }
  }

  p {
    color: inherit;
    font-size: 0.8rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    line-height: 3vh;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
}
</style>
