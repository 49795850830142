<template>
  <div class="stretch">
    <router-view></router-view>
  </div>
</template>

<script>
import metaHelpers from '@/scripts/helpers/metaHelpers.js';

export default {
  name: "App",
  components: {
  },
  data() {
    return {
      title: null
    }
  },
  mounted: function () {
    var vals;
    metaHelpers.setupPage(this);
    vals = metaHelpers.getMetaValues();
    this.title = vals.title;
  }
};
</script>

<style lang="sass">
@import './styles/variables'
@import './styles/layout'
@import './styles/themes'
@import '~bootstrap/dist/css/bootstrap.css'
</style>