<template>
  <li></li>
</template>

<script setup>



</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

li {
  flex-basis: 100% !important;
  padding: 0;
  border-top: 1px solid $gray-300;
}
</style>

