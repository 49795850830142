export default {
    getInnerHeight: function (el) {
        var computed = getComputedStyle(el),
            padding = parseInt(computed.paddingTop) + parseInt(computed.paddingBottom);

        return el.clientHeight - padding
    },

    htmlEncode: function (value) {
        return value.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
            return '&#' + i.charCodeAt(0) + ';';
        });
    },

    embedLinks: function (text) {
        var replacePattern1,
            replacePattern2,
            replacePattern3,
            result;

        //URLs starting with http://, https://, or ftp://
        replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        result = text.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

        //URLs starting with www. (without // before it, or it'd re-link the ones done above)
        replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        result = result.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

        //Change email addresses to mailto:: links
        replacePattern3 = /(([a-zA-Z0-9_\-\.]+)@[a-zA-Z_]+?(?:\.[a-zA-Z]{2,6}))+/gim;
        result = result.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

        return result
    },

    getVideoFlexBasis: function (containerWidth, containerHeight, count, maxWidth = 1) {
        var result = {},
            gutter = 20,
            rows,
            ratio = 16 / 9,
            newWidth,
            cols = 1;

        if (count === 1) {
            maxWidth = 0.5;
        }

        // Work out how many columns are needed for the width to be low enough for the heights to fit in the available space
        while (Math.ceil((count / cols)) * ((containerWidth * (1 / cols)) / ratio + gutter) - gutter > containerHeight) {
            cols++;
        }

        // How many rows will there be?
        rows = Math.floor((containerHeight + gutter) / ((containerWidth * (1 / cols)) / ratio + gutter));

        // How wide should each video be?
        if (cols === 1) {
            result.videoWidth = containerWidth * maxWidth;
        }
        else {
            result.videoWidth = (Math.min(1 / cols, maxWidth) * (containerWidth - gutter) - gutter);

            // Check if we can get wider videos if all are fit within a single column
            //result.videoWidth = Math.max(result.videoWidth, ((containerHeight / count) - gutter) * ratio);    // Check if we can get wider videos if all are fit within a single column
            newWidth = ((containerHeight / count) - gutter) * ratio;
            if (newWidth > result.videoWidth) {
                result.videoWidth = newWidth;
                cols = 1;
            }
        }

        // Container width can be reduced so the videos wrap into a cluster
        // Otherwise their might be 4 on row 1 and only 1 on row 2
        result.containerWidth = Math.ceil(count / rows) * (result.videoWidth + gutter) + (result.videoWidth / 2);

        return result;
    },

    getVideoMaxWidth: function (containerWidth, containerHeight) {
        var result;

        result = Math.min(containerHeight * (16 / 9), containerWidth);

        return result;
    },


    // Watch for resize of a specified element
    watchResize: function (resizeObservers, key, el, val, callback) {
        if (el) {
            if (!resizeObservers[key]) {
                resizeObservers[key] = new ResizeObserver(() => { this.watchResize(resizeObservers, key, el, val, callback) });
                resizeObservers[key].observe(el);
            }

            if (val) {
                val.value = val.value || {};
                val.value.height = el.clientHeight;
                val.value.width = el.clientWidth;
            }

            if (callback) {
                callback();
            }
        }
    },

    getPopupPosition: function (el, anchor) {
        var result = null,
            width,
            height,
            anchorPos;

        if (el) {
            result = {};

            if (anchor) {
                anchorPos = anchor.getBoundingClientRect();

                // If closer to the left of the window
                if (window.innerWidth - anchorPos.right > anchorPos.left) {
                    result.left = `${anchorPos.left}px`;
                }
                // If closer to the right
                else {
                    width = el.offsetWidth;
                    result.left = `calc(${anchorPos.right - width}px)`;
                }

                // If closer to the top of the window
                if (window.innerHeight - anchorPos.bottom < anchorPos.top) {
                    height = el.offsetHeight;
                    result.top = `calc(${anchorPos.top - height}px - 1vh)`
                }
                // If closer to the bottom
                else {
                    anchorPos.height = anchorPos.height || el.offsetHeight;
                    result.top = `calc(${anchorPos.top}px + ${anchorPos.height}px + 1vh)`;
                }
            }
            else {
                result.left = `calc(${(window.innerWidth - el.offsetWidth) / 2}px)`;
                result.top = `calc(${(window.innerHeight - el.offsetHeight) / 2}px + 1vh)`;
            }
        }

        return result;
    }
}