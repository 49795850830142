<template>
  <template v-if="participant && status">
    <div ref="statusDot" :class="['status-dot', statusClass]">
      <i class="fa fa-exclamation"></i>
    </div>
    <ToolTip :message="message" :anchor="statusDot" />
  </template>
</template>

<script setup>
import ToolTip from "@/components/ToolTip.vue";
import { ref, computed } from "vue";

const statusDot = ref(null);

const props = defineProps({
  status: Object,
  online: Boolean,
  participant: Object
});

const statusClass = computed(() => {
  return isError() ? 'error' : 'ok';
});

function isError() {
  return props.online === false || props.status.network === 'very-low';
}

function isWarning() {
  return props.status.network === 'low' || props.status.cpu === 'high';
}

const message = computed(() => {
  var result;

  if (props.online === false) {
    result = getOfflineMessage();
  }
  else if (isWarning() || isError()) {
    result = getStatusWarningMessage();
  }

  return result;
});

function getOfflineMessage() {
  var result;

  result = `${props.participant.local ? 'Your' : `This participant's`} device has no internet connection`;

  if (props.participant.local) {
    result += '. Try restarting your router or select a different connection.';
  }

  return result;
}

function getStatusWarningMessage() {
  var result;

  result = `${props.participant.local ? 'Your' : `This participant's`} device has `;

  if (props.status.cpu === 'high') {
    result += `high CPU usage`;
  }

  if (props.status.network === 'low') {
    result += `${props.status.cpu === 'high' ? ' and ' : ''}a slow network connection`;
  }
  else if (props.status.network === 'very-low') {
    result += `${props.status.cpu === 'high' ? ' and ' : ''}a very poor network connection`;
  }

  if (props.participant.local) {
    result += '. Try closing down unnecessary apps or browser tabs.'
  }

  return result;
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.status-dot {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: white;
  font-size: 1.4vh;
  justify-content: center;
  align-items: center;
  display: flex;

  &.ok {
    display: none;
  }

  &.warning {
    background: $orange;
  }

  &.error {
    background: $red;
  }
}
</style>
