<template>
    <div class="check-question">
        <h4>{{ question }}</h4>
        <div class="check-question-buttons">
            <div class="button primary button-big" @click="onYesClick">Yes</div>
            <div class="button secondary button-big" @click="onNoClick">No</div>
        </div>
    </div>
    <div class="check-content">
        <slot></slot>
    </div>
    <div v-if="deviceName" class="device-change">
        <div ref="button" class="button secondary" @click="onButtonClick">Change {{ deviceName }}</div>
        <DevicePopupMenu ref="menu" :devices="devices" :anchor="button" @deviceClick="onDeviceClick">
        </DevicePopupMenu>
        <ToolTip ref="tooltip"
            message="No devices found. You may need to change your browser settings to give permission to this page to use your devices."
            :anchor="button" />
    </div>
    <div v-if="hasDevices" class="skip"><a href="#" @click="onSkipClick">Skip</a></div>
</template>
  
<script setup>
import { computed, ref } from 'vue';
import DevicePopupMenu from "@/components/menus/DevicePopupMenu.vue";
import ToolTip from "@/components/ToolTip.vue";
import _ from 'lodash';

const emit = defineEmits(['deviceChange', 'yesClick', 'noClick', 'skipClick']);

const props = defineProps(['title', 'question', 'deviceName', 'devices']);

const menu = ref(null),
    tooltip = ref(null),
    button = ref(null);

const hasDevices = computed(() => {
    return props.devices && props.devices.length > 0;
});

function onButtonClick() {
    if (!props.devices || props.devices.length === 0) {
        tooltip.value.toggle(true, 6000);
    }
    else {
        menu.value.toggle();
    }
}

function onDeviceClick(deviceId) {
    emit('deviceChange', deviceId);
}

function onYesClick() {
    emit('yesClick');
}

function onNoClick() {
    emit('noClick');
}

function onSkipClick() {
    emit('skipClick');
}

</script>
  
<style lang="scss" scoped>
.device-change {
    margin-top: 30px;
    position: relative;
    display: inline-block;
}

h3 {
    margin-bottom: 30px;
}

.skip {
    margin: 5vh;
    font-size: 0.8rem;
}
</style>
  